import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    nav: {
        type: 'dark',
        variant: 'blue',
        logo: 'logo_blanco.webp'
    }
  },
  mutations: {
    styleNav(state, payload) {
      state.nav.type = payload.type
      state.nav.variant = payload.variant
      state.nav.logo = payload.logo
    },
    navVariant(state, payload){
        state.nav.variant = payload
    }
  }
})

export default store