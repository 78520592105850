<template>
  <div id="metodos-pago">
    <h1 id="tituloMediodePago">{{$tc('pagos.title')}}</h1>
    <div style="width: 100%; overflow: auto">
      <b-container style="margin: 0 auto; min-width: 960px; max-width: 1280px">
        <b-row   id="contenedorTabla" class="py-5 px-3">
          <b-col cols="4" >
              <div id="titulo1">
                <h6>{{$tc('pagos.header1')}}</h6>
              </div>

              <div id="item" >
                <p id="item-p">{{$tc('pagos.item1_header1')}}</p>
                <p id="item-p">{{$tc('pagos.item1_header1_part2')}}</p>
              </div>

              <div id="item">
                <p id="item-p">{{$tc('pagos.item2_header1')}}</p>
                <p id="item-p">{{$tc('pagos.item2_header1_part2')}}</p>
              </div>

              <div id="item">
                <p id="item-p">{{$tc('pagos.item3_header1')}}</p>
                <p id="item-p">{{$tc('pagos.item3_header1_part2')}}</p>
              </div>
              
              <!-- <div id="item">
                <p id="item-p">{{$tc('pagos.item4_header1')}}</p>
                <p id="item-p">{{$tc('pagos.item4_header1_part2')}}</p>
              </div> -->
            
          </b-col>
          <b-col cols="2" md="2" >

            <div id="titulo2">
              <h6>{{$tc('pagos.header2')}}</h6>
            </div>
                
            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
        
          </b-col>
          <b-col cols="2" md="2" >
            <div id="titulo2">
              <h6>{{$tc('pagos.header3')}}</h6>
            </div>

            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
          </b-col>

          <b-col cols="2" md="2">
            <div id="titulo2">
              <h6  >{{$tc('pagos.header4')}}</h6>
            </div>

            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
          </b-col>

          <b-col cols="2" md="2">
            <div id="titulo5">
                <h6 :style="{fontSize:'15px'}">{{$tc('pagos.header5')}}</h6>
            </div>  

            <div id="item-icon">
              <b-icon-check-circle-fill class="h3"></b-icon-check-circle-fill>
            </div>
            <div id="item-icon">
              <p id="item-p-icon-disable"></p>
            </div>
            <div id="item-icon">
              <p id="item-p-icon-disable"></p>
            </div>
          </b-col>
          
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
    name: 'MetodosPago',   
}
  
</script>

<style>
 #tituloMediodePago{
   background-color: black;
   color:white;
   padding:48px 0px;
   font-size: 32px;   
 }
 #titulo1{
   background-color: #ff00ff;
   color:white;
   display:flex;
   align-items: center;
   flex-direction: column;
   padding: 15px 0px;
   margin-bottom: 1vw;
 }
 #item{
  background-color:#0000FF ;
  color:white;
  padding: 1vw 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1vw;
  height: 75px;
 }

#titulo2{
  background-color: #BAFF00;
   color:black;
   display:flex;
   align-items: center;
   flex-direction: column;
   padding: 15px 0px;
   margin-bottom: 1vw;
   
}
#titulo5{
  background-color: #BAFF00;
   color:black;
   display:flex;
   align-items: center;
 
   height: 57px;
   margin-bottom: 1vw;
}
#titulo5 h6{
  margin-bottom: 0px;
}

#item-p{
  margin-bottom: 0px;
}

#item-icon{
  background-color:#E6E7EB ;
 height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1vw;
 }

 #item-p-icon-disable{
  height: 20px;
 }
 /* #contenedorTabla{
  
   min-width: 900px;
    overflow: auto;
 } */

 
</style>