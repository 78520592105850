<template>
  <div id="open-house">
    <div id="open-house-title">
      <h2>{{$tc('openhouse.t1')}}</h2> 
    </div>
    <div class="py-3" id="open-house-p">
      <p>{{$tc('openhouse.p')}}</p>
    </div>
    <div id="tdate">
      <p>{{$tc('openhouse.tdate')}}</p>
    </div>
    <div id="next-date"  v-if="nextOpenhouse">
      <p>{{
        $tc(`date.day.${openHouseDate.day}`) 
        + 
        ` ${openHouseDate.date} `
        + 
        $tc(`date.month.${openHouseDate.month}`)
        }}
      </p>
    </div>
    <div v-else>
      <p>{{$tc(`openhouse.data`)}}</p>
    </div>
    <div id="schedules" v-if="nextOpenhouse">
      <p>ARG: {{nextOpenhouse[0].scheduleARG}}</p>
      <p>CH: {{nextOpenhouse[0].scheduleCHI}}</p>
      <p>CO: {{nextOpenhouse[0].scheduleCOL}}</p>
      <p>UY: {{nextOpenhouse[0].scheduleURU}}</p>
    </div>
    <b-btn v-if="nextOpenhouse" href="https://info.mindhubweb.com/openhouse" id="btn">{{$tc('openhouse.btn')}}</b-btn>
  </div>
</template>
<style scoped>
#open-house {
  background-color: black;
  color: white;
  padding: 2rem;
  min-height: 100%;
}
#open-house-title {
  color: greenyellow;
}
#open-house-p {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}
#tdate {
  color: #fe00fe;
  font-weight: bold;
  font-size: 1.2rem;
}
#next-date {
  font-size: 1.2rem;
  font-weight: bold;
}
#schedules {
  margin: 1rem auto;
}

#btn {
  color: #ffffff;
  background-color: #fe00fe;
  border-radius: .8rem;
  padding: .8rem 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
</style>

<script>
export default {
  name: 'Openhouse',
  data: () => ({
      nextOpenhouse: {},
  }),
  computed: {
    openHouseDate(){
      if(this.nextOpenhouse != null && this.nextOpenhouse.length != 0){

        let openHouseDate = new Date(this.nextOpenhouse[0]?.date)
        console.log({
          day: openHouseDate.getDay(),
          date: openHouseDate.getDate(),
          month: (openHouseDate.getMonth() + 1)
        });
        return {
          day: openHouseDate.getDay(),
          date: openHouseDate.getDate(),
          month: (openHouseDate.getMonth() + 1)
        }
      } else {
        return this.lang == 'es' ? "no disponible" : "no data"
      }
    },
  },
  methods: {
  async getNextOpenhouse() {     

    try {
      const response = await fetch(`https://strapi-dev.mindhublab.com/api/openhouses`, {
        headers: {
          'Authorization': `Bearer 44e149675f3a6d5fcb758eda0824f15592ff2966f51479368717354c0ea678a7a744086e89d99c19564ddc67924cedf51f03fee191a95149adabc114334b1643994f538f7699f59edf1ba4029f860a2976be449843264d0c4af82ad0f30123517ab6826342d3f53f68e238baa2ad8ddc7b521dc0ac5da61f61bdc87c64c0a988`,
        }
      });

      const json = await response.json();

      const Openhouses = json.filter(b => {
        let openHouseDate = new Date(b.date);
        let today = new Date();
        console.log(Openhouses);
        return today <= openHouseDate;
      });

      this.nextOpenhouse = Openhouses;
    } catch (err) {
      console.error(err);
    }
  }
},
  beforeMount(){
    this.getNextOpenhouse()
  }
}
</script>