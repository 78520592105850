import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SomosMindhub from '../views/SomosMindhub.vue'
import NuestrosBootcamps from '../views/NuestrosBootcamps.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/conoce-mindhub',
    name: 'ConoceMindhub',
    component: () => import('../views/ConoceMindhub.vue')
  },
  {
    path: '/empresas',
    name: 'EresEmpresa',
    component: () => import('../views/EresEmpresa.vue')
  },
  {
    path: '/somos',
    name: 'SomosMindhub',
    component: SomosMindhub
  },
  {
    path: '/bootcamps',
    name: 'NuestrosBootcamps',
    component: NuestrosBootcamps
  },
  {
    path: '/prensa',
    name: 'Prensa',
    component: () => import('../views/Prensa.vue')
  },
  {
    path: '*',
    redirect: '/404', // Redirige a una página de error 404
  },
  {
    path: '/404',
    component: () => import('../views/Error404.vue'), // Crea un componente para la página de error 404
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
