<template>
  <div id="boot">
    <div id="boot2">
    <h1>{{$t('bootcamp.t1')}}</h1>
    </div>
    <b-container fluid class="bv-example-row">
      <b-row class="mb-5">
        <!-- Mi primer sitio web -->
        <b-col class="bg-light px-0 pb-3">
          <h3 id="mpsw" style="font-weight:bold">{{ $tc('bootcamp.mpsw', 1)}} <br>{{ $tc('bootcamp.mpsw', 2)}}</h3><br>
          <p style="max-width: 1000px; margin: .5rem auto">{{$t('bootcamp.mpswP')}}</p>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <!-- Java Bootcamp -->
        <b-col class="d-flex flex-column justify-content-between px-0 pr-lg-2 flex-grow-1" cols="12" lg="6">
          <Bootcamp type="fs_java" />
        </b-col>
        <!-- MERN Bootcamp -->
        <b-col class="d-flex flex-column justify-content-between px-0 pl-lg-2 flex-grow-1" cols="12" lg="6">
          <Bootcamp type="fs_mern" />
        </b-col>
        
        <!-- Toast de form enviado -->
        <b-toast
          v-model="showToast"
          variant="success"
          :auto-hide-delay="10000"
          :title="$tc('formgeneric.toast.t1')"
        >
          <p>
            {{ $t('formgeneric.toast.t2') }}
          </p>
            <p>
              {{ $t('formgeneric.toast.t3') }}
              <span>
                <a
                  href="https://cloud.edu.mindhubweb.com/openhouse"
                  target="_blank"
                  style="text-decoration: underline"
                  >
                  Open House
                </a>
              </span>.
            </p>
        </b-toast>
      </b-row>
      <b-row>
        <b-col class="bootcamps"><h6>{{$t('bootcamp.footer')}}</h6> </b-col>
      </b-row>
      
    </b-container>
  </div>
</template>

<script>
import Bootcamp from './bootcamp.vue';

export default {
  name: 'Bootcamps',
  components: {
    Bootcamp,
  },
  data() {
    return {
      showToast: false,
    }

  },
  created() {
      this.verificarURL();
      this.clearSendedHash();
  },
  methods: {
    showAlert() {
            this.showToast = true;
    },
    verificarURL() {
        const url = window.location;
        console.log(url)
        const successSend = url.href.includes("#sended");

        if (successSend && this.showToast === false) {
            this.showAlert();
        }
    },
    clearSendedHash() {
      const location = window.location;
      if (location.hash) {
        setTimeout(() => {
          const newURL = location.href.split('#')[0];
          history.replaceState({}, document.title, newURL);
        }, 3000);
      }
    }
  }
}
</script>
<style>

#mpsw{
  background-image: url('../assets/bt2.jpg');
  background-size: cover;
  background-position: center;
  padding: 2em 0;
}

#boot button{
  margin: 18px 0;
}
#boot2 h1{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: #FF00FF;
    font-size: xxx-large;
    font-weight: 900;
}
#boot2 p{
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    margin-bottom: 4em;

}
#boot .blueButton{
  background-color: #0000FF;
  border: none;
}
#boot h3{
    color: #fff;
    margin-top: 0em;
}
#boot{
    background-color: white;
    padding-bottom: 4%;
}
#boot .purpleButton{
  background-color: #FF00FF;
  border:none;
}
.bootcamps{
    background-color: #0000FF;
    color: white;
    padding-top: 10px;
    padding-bottom: 5px;
    font-weight: normal;
    text-align: center;
}

</style>