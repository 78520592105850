
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  es: {
    error404: {
      title: "404 - Página no encontrada",
      subtitle: "Lo sentimos, la página que estás buscando no existe.",
      buttonLabel: "Ir a la página de inicio"
    },
    navblack: {
      p1: 'PRÓXIMO BOOTCAMP',
      p2: 'APLICA AHORA'
    },
    navwhite: {
      navitem1: {
        p1: 'SOMOS',
        p2: 'MINDHUB'
      },
      navitem2: {
        p1: 'PROGRAMACIÓN',
        p2: 'WEB'
      },
      navitem3: {
        p1: 'MINDHUB',
        p2: 'ALUMNOS'
      },
      navitem4: {
        p1: 'MINDHUB',
        p2: 'EMPRESAS'
      },
      navitem5: {
        p1: 'APLICA',
        p2: 'AHORA'
      },
    },
    homebanner: 'FORMAMOS LOS PROFESIONALES IT DEL MAÑANA | Nuestros programas de formación IT te preparan para la nueva era digital ',
    aplicar: {
      t1: 'Cómo aplicar',
      p1: 'Eliges tu bootcamp preferido | (Full Stack Java / Full Stack | Mern) ',
      p2: 'Te inscribes para ser el | dueño de tu futuro',
      p3: 'Cursas y apruebas | "Mi primer sitio web" | (64hs)',
      p4: 'Cursas tu bootcamp | elegido',
      p5: 'Te ayudamos a construir | tu perfil laboral',
      p6: '¡Consigues trabajo | y eres feliz!',
      insc: 'INSCRÍBETE'
    },

    bootcamp: {
      t1: 'Nuestros Bootcamps',
      p1: 'Nuestros programas de estudio responden a las necesidades actuales de tecnología del mercado, | todos son precedidos por el modulo de Mi Primer Sitio Web. Puedes cursarlos en modalidad Full Time o Part Time.',
      mpsw: 'Mi primer sitio | web',
      mpswP: 'Animate a dar el primer paso en el mundo del desarrollo. Aprenderás HTML y CSS desde cero, para construir tu primer sitio web. Una vez cursado este Programa continuarás con el Bootcamp elegido.',
      how: '¿Cómo me postulo?',
      java: 'Full Stack JAVA | Immersive Bootcamp',
      javaDescription: "Aprenderás a diseñar y desarrollar páginas web completas, desde la creación de interfaces de usuario dinámicas utilizando tecnologías front-end hasta la implementación de servicios y lógica de negocio en el back-end. <br> Utilizarás el lenguaje JAVA a través de frameworks, como Spring Boot, que aceleran y facilitan tanto el conocimiento como el desarrollo de las aplicaciones web. <br> Aprende HTML5, CSS3, Javascript, JAVA y más.",
      javaP: '¿Quieres dominar los lenguajes de programación más demandados del mercado?',
      mern: 'Full Stack REACT JS | Immersive Bootcamp',
      mernDescription: 'Aprenderás a diseñar y desarrollar páginas web de principio a fin, integrando de manera fluida interfaces de usuario en el front-end con funcionalidades sólidas en el back-end. Crearás experiencias de usuario dinámicas y receptivas haciendo uso de tecnologías y lenguajes de programación como HTML5, CSS3, Javascript, MongoDB, Express, React y Node Js.',
      mernP: '¡Aprende todo sobre desarrollo web y tecnologías como React Native y Selenium!',
      date: 'Próximas fechas',
      modality: 'Modalidad',
      duration: 'Duración',
      schedule: 'Horarios',
      price: 'Precio',
      signUp: 'QUIERO INSCRIBIRME',
      signUpNewJava: 'INSCRIBIRME AL PROGRAMA',
      goOpenHouse: 'IR AL OPEN HOUSE',
      download: 'DESCARGA EL PROGRAMA',
      opt1: 'Selecciona tu país',
      opt2: 'Argentina',
      opt3: 'Chile',
      opt4: 'Colombia',
      opt5: 'Uruguay',
      opt6: 'Resto del mundo',
      discount: '20% de descuento si pagas mientras estudias.',
      footer: 'Ambos Bootcamps son precedidos por el Programa inicial Mi Primer Sitio Web',
      apply: "Postúlate al Programa",
      applyDescription: "A diferencia de la mayoría de cursos intensivos de programación, nosotros no necesitamos que tengas ningún conocimiento ni experiencia previos.Para participar, tienes que tener 18 años cumplidos, documento de identidad del país donde resides y título secundario.",
      form: "Completa el formulario",
      formDescription: "Cuéntanos sobre ti, tus conocimientos y tus intereses.",
      challenge: "Completa el desafío MindHub",
      challengeDescription: "Vas a recibir un challenge para resolver.",
      blended: "Elige tu Plan de financiamiento",
      blendedDescription: "Comienza el Bootcamp con una de las opciones elegidas: Pago Tradicional, Pago Diferido o Pago Blended.",
      bootcamp: "Realiza el Bootcamp",
      bootcampDescription: "Por 4 meses en promedio, solo preocúpate por aprender..",
      financing: "Financiamiento",
      financingDescription: "Si elegiste alguna opción de financiamiento, paga sólo cuando consigas un trabajo con un sueldo equivalente o mayor al de un Desarrollador Jr. de tu país de residencia.",
      description: "Cuéntanos de ti y de tus ganas de convertirte en un desarrollador.Completa el desafío y listo. Solo queda esperar a ver si formas parte de esta camada de Hubbers donde armaremos grupos diversos que puedan formar un verdadero equipo de trabajo.",
      title: "¿DÓNDE TRABAJAN NUESTROS HUBBERS?",
      brandTitle: "Nuestro Ecosistema",
      train: "Capacitamos a más de 2000 chicos y chicas en tecnología. Nuestros Hubbers, egresados de bootcamps Full Stack, hoy trabajan como Full Stack Developers en las principales empresas y organizaciones.",
      join: "¿Quieres acompañarlos?",
    },
    modalities: {
      a_distancia: 'A distancia',
      blended: 'Blended',
      presencial: 'Presencial',
      virtual_en_vivo: 'Virtual en Vivo'
    },
    pagos: {
      title: 'Métodos de pago por país',
      header1: 'Método de pago disponible',
      header2: 'Argentina',
      header3: 'Chile',
      header4: 'Colombia',
      header5: 'Resto de America y el mundo',
      item1_header1: 'PAGO TRADICIONAL',
      item1_header1_part2: '[Paga mientras cursas con descuento]',
      item2_header1: 'PAGO DIFERIDO',
      item2_header1_part2: '[Paga cuando consigas trabajo]',
      item3_header1: 'PAGO BLENDED',
      item3_header1_part2: '[Combina las primeras opciones]',
      item4_header1: 'PAGA EN CUOTAS',
      item4_header1_part2: '[12 cuotas con descuento]'
    },
    openhouse: {
      t1: 'Conoce más en el OPEN HOUSE',
      p: 'Una charla informal donde podrás conocer experiencias y cómo es el día a día cursando el Bootcamp.',
      tdate: 'PRÓXIMA FECHA',
      btn: 'INSCRIBIRME AL OPEN HOUSE',
      data: "NO DISPONIBLE",
    },
    date: {
      day: {
        0: 'Domingo',
        1: 'Lunes',
        2: 'Martes',
        3: 'Miércoles',
        4: 'Jueves',
        5: 'Viernes',
        6: 'Sábado',
      },
      month: {
        1: 'de Enero',
        2: 'de Febrero',
        3: 'de Marzo',
        4: 'de Abril',
        5: 'de Mayo',
        6: 'de Junio',
        7: 'de Julio',
        8: 'de Agosto',
        9: 'de Septiembre',
        10: 'de Octubre',
        11: 'de Noviembre',
        12: 'de Diciembre',
      },
    },
    carousel: {
      c1: 'Aprende programación <br> Full Stack en 4 meses',
      c2: 'Más de 5000 egresados',
      c3: 'Nuestros bootcamps <br> simulan un ámbito laboral <br> desde el primer día',
      c4: 'Aprende las habilidades de programación más <br> demandadas en el mercado IT',
      c5: 'Paga recién <br> cuando consigas trabajo',
    },

    carouselEmpresa: {
      c1: 'Capacitaciones en tecnología para empresas',
      c2: 'Reskilling y Upskilling para colaboradores',
      c3: 'Programas de becas para formación IT',
      c4: 'Data, .Net, Java, Cloud, Full Stack',
    },

    carousel2: {
      c1: 'Somos la empresa <br>naciente #1 de Argentina',
      c2: 'Noticias'
    },

    empresas: 'Consigue tu primer empleo en tecnología en las mejores empresas',

    footer: {
      t1: '¿Quieres formar parte de nuestra comunidad?',
      p1: 'Te vamos a contar sobre nuestros eventos, nuevos workshops y todas las novedades',
      placeholder: 'Nombre',
      p2: '¿Cómo prefieres que te contactemos?',
      t2: '¿Quieres trabajar | en MindHub?',
      button: 'ENVIAR',
      a: 'Envíanos tu CV',
      t3: 'Términos | y condiciones',
      li1: 'FAQ',
      li2: 'TERMS',
      // li3: 'PRIVACY',
      t4: 'Contacto',
      tg: 'Somos MindHub, estamos formando | los talentos de la nueva era digital',
      p3: '*Esta web fue desarrollada por alumnos egresados de MindHub utilizando contenidos | de HTML, CSS, JavaScript, Vue y Bootstrap, aprendidos en nuestros bootcamps.',
    },

    inscribete: {
      t1: '¿Estás preparado para sumarte a la revolución y empezar tu carrera digital con nosotros?',
      tb: 'INSCRÍBETE',
      in1: 'Nombre y Apellido',
      in2: 'E-mail',
      in3: 'Ciudad',
      in4: 'Mensaje',
    },
    somosmh: {
      t1: 'En MindHub, formamos los talentos digitales del futuro',
      p1:'En MindHub integramos educación con empleo a través de programas intensivos de capacitación en tecnologías digitales.',
      p2: 'Formamos alumnos y staff de empresas  en habilidades digitales brindando educación de calidad, alineados con los Objetivos de Desarrollo Sustentable de las Naciones Unidas.',
      p3: '¿Buscas adquirir las principales habilidades digitales y conseguir un trabajo en la industria de tecnología?',
      t2: 'Tu camino como Hubber puede empezar hoy',
      vision: {
        title: 'NUESTROS RESULTADOS',
        body: 'Ser líder en el desarrollo de talento digital para la | empleabilidad en la nueva economía.'
      },
      mission: {
        title: 'Nuestra Misión',
        body: 'Desarrollar habilidades relevantes en el | mercado para que las personas puedan | acceder a un futuro mejor.'
      },
      founders: 'NUESTRAS FUNDADORAS',
      p33: "25 años de experiencia en la industria IT.",
      p34:"+20 años en Accenture como Managing Directors con distintas responsabilidades regionales.",
      p35:"6 años liderando MindHub, startup educativa de alto crecimiento en Latinoamérica.",
      t3: 'NUESTROS MERCADOS',
      p4: 'Para alcanzar el éxito no basta con tener pasión y una misión, también es fundamental la | calidad profesional y humana | del equipo que acompaña. ',
      p5: 'Y nuestro equipo no estaría completo sin una larga lista de personas que nos ayudaron a crear nuestro propio | efecto mariposa.',
      p6: 'Porque MindHub es posible gracias a una innumerable cantidad de amigos, colegas y mentores que estuvieron al lado nuestro desde el comienzo; dedicándonos su tiempo y experiencia, y aportando sus ideas y creatividad.',
      p7: 'Pequeños gestos que iban a tener una gran influencia en nuestro futuro. Y es por eso que no queremos dejar de decirles',
      p8: ' GRACIAS a todos ellos por ser integrantes clave de este gran equipo.',
      p9: 'Juntos | empezamos a operar en 2018 en los principales países de América Latina.',
      p10: 'Juntos | ganamos en 2019 el premio al mejor emprendimiento nuevo (elegidos por la principal escuela de negocios de Argentina).',
      p11: 'Juntos |, además, llegamos a ser premiados por la Fundación Citi y la Fundación Rockefeller por nuestras iniciativas de Equidad de Género.',
      p12: 'Juntos | en 2020 nos internacionalizamos y llegamos a los primeros 1000 alumnos, primeros de muchos más por venir.',
      p13: 'Somos un equipo que se emociona cada vez que un egresado de MindHub consigue trabajo o vuelve a contarnos como logró cambiar su vida.',
      t4: 'Un equipo con grandes logros, | pero más aún, | con grandes sueños.',
      nestrosmercados: {
        card1: {
          title: 'FUTUROS <br> PROGRAMADORES',
          desc: 'Acercamos a las personas <br> al mundo de la programación mediante:<br> <br>- Programas sincrónicos, intensivos de programación Full Stack en JAVA y REACT JS.<br>-Opciones de financiamiento para la formación IT.',
        },
        card2: {
          title: 'EMPRESAS',
          desc: 'Ayudamos a potenciar las habilidades<br> técnicas de los colaboradores mediante:<br> <br>- Programas de formación<br> IT para nuevos ingresantes.<br>- Programas de upskilling y reskilling diseñados para staff IT o de otras áreas funcionales.',
        },
        card3: {
          title: 'BECAS DE FORMACIÓN IT',
          desc: 'Trabajamos con Gobiernos, Cámaras de empresas, ONGs y empresas para:<br> <br>- Articular programas de becas<br> para poblaciones vulnerables.<br>- Democratizar el acceso<br> a la educación en tecnología.',
        },
      },
      button: 'CONTÁCTANOS'
    },
    nuestrosboot: {
      t1: '¿Estás preparado para sumarte a la revolución y empezar tu carrera digital con nosotros?',
      p1: 'En MindHub usamos la metodología "Learning by doing", que nos permite una alta empleabilidad en nuestros egresados. En cada bootcamp simulamos un ámbito laboral en donde te vamos a proponer proyectos innovadores para que puedas conseguir un nivel de 0 a 100, sin necesidad de tener conocimientos previos. Nuestros mentores y supervisores te van a acompañar en todo el proceso y asi podrán identificar áreas de mejora en tiempo real y ajustar el método a las necesidades de cada alumno.',
      p2: '5 años',
      p3: 'capacitando | talento | digital'
    },
    financing: {
      title: 'Alternativas de Financiación',
      desc: 'Ofrecemos distintas alternativas para que tu camino de | aprendizaje sea cómodo y acorde a tu contexto.',
      trad: {
        title: 'Pago tradicional',
        desc: 'Paga mientras estudias',
        p: 'Paga del modo tradicional, al comienzo del Bootcamp (luego de haber cursado "Mi Primer Sitio Web"), en 1 o más cuotas con atractivos descuentos.'
      },
      work: {
        title: 'Pago diferido',
        desc: 'Paga recién cuando trabajes',
        p: 'Paga cuando estés trabajando con ingresos equivalentes al de un Desarrollador Jr. Una vez que hayas finalizado con éxito "Mi Primer Sitio Web", firmarás un Acuerdo de Ingresos Futuros equivalente al monto del Bootcamp para poder pagar en cuotas la totalidad del costo.'
      },
      blend: {
        title: 'Pago blended',
        desc: 'Arma tu propio plan de pago',
        p: 'Si quieres pagar una parte del Bootcamp y posponer el resto del pago para cuando estés trabajando, podemos diseñar un plan que se adapte a tus posibilidades.'
      },
    },
    conoce: {
      title: '¿Quieres saber cómo | es un día en MindHub?',
      subtitle: 'Un recorrido virtual por el camino que transitan los estudiantes.',
      card1: {
        title: 'Daily Meeting',
        body: 'El día comienza con una daily meeting entre los estudiantes y el mentor, donde se revisan los avances del trabajo realizado el día anterior, las dificultades que nos trabaron en el desarrollo y las tareas a realizar en el día.',
        hours: '9 hs.'
      },
      card2: {
        title: 'Daily Workshop',
        body: 'A media mañana el mentor realiza su workshop diario donde expone los temas y conceptos nuevos para encarar la tarea del día.',
      },
      card3: {
        title: 'Mentor 1:1',
        body: 'La mañana sigue con reuniones 1 a 1 entre los alumnos y su mentor, despejando dudas para poder avanzar en los proyectos.',
      },
      card4: {
        title: 'Code Check',
        body: 'Antes del almuerzo se realiza un explained code, donde los participantes defienden su código. Se revisan los desarrollos para que cumplan con los standards de la industria.',
        hours: '13 hs.'
      },
      card5: {
        title: 'Almuerzo',
      },
      card6: {
        title: 'Game & Learn',
        body: 'Una vez por semana, la tarde comienza con una propuesta lúdica, donde a través de la gamificación podrás fijar los conocimientos teóricos aprendidos.',
        hours: '14 hs.'
      },
      card7: {
        title: 'Pair Programming & Pair Reviews',
        body: 'Luego es momento de saltar al código nuevamente a través de actividades donde se fomenta el trabajo entre pares. | *Las mismas contarán con el soporte de tu mentor para que puedas avanzar con fluidez.',
      },
      card8: {
        title: 'Retro Meeting',
        body: 'Tu día termina con una Retro Meeting donde se revisa el avance del día contra el plan de trabajo y se toman acciones para comenzar con todo el día siguiente.',
      },
      banner1: 'Un ecosistema de aprendizaje <br> con foco en tu futuro',
      p1: 'No queremos encerrarte en un aula sino abrirte las puertas del mundo laboral. <br>¿Cómo? Haciéndote parte de un ecosistema de aprendizaje continuo en el que <br> tu futuro sea el centro de todo.',
      icon1: {
        title: 'Aprende',
        desc: 'Sumando conocimientos, lenguajes y razonamientos nuevos.'
      },
      icon2: {
        title: 'Experimenta',
        desc: 'Llevando a la práctica lo que aprendiste, con el feedback 1 a 1 de tu mentor.'
      },
      icon3: {
        title: 'Trabaja',
        desc: 'Resolviendo los problemas reales para clientes de todo el mundo.'
      },
      icon4: {
        title: 'Sigue tu propio camino',
        desc: 'Consiguiendo lugar en las empresas más importantes de la industria.'
      }
    },
    eresempresa: {
      t1: '¿Necesitas sumar o <br> reconvertir el talento  <br> digital en tu  empresa?',
      t2: 'EN MINDHUB POTENCIAMOS TU EQUIPO',
      p1: 'Eleva el desempeño de tus colaboradores con nuestras capacitaciones en tecnología.',
      button1: 'CONTRATA A NUESTROS EGRESADOS',
      t3: '¿Qué podemos ofrecerte?',
      t4: 'Nuestro ecosistema',
      headerCard: {
        title: 'Profesionales listos <br> para incorporarse',
        desc: 'Nuestros egresados dedican entre 600 a 800 horas simulando un entorno real de trabajo, saliendo listos para insertarse en equipos de desarrollo de forma productiva desde el minuto cero.'
      },
      card1: {
        title: 'Contrata desarrolladores <br> Full Stack',
        desc: 'Contamos con desarrolladores Full Stack listos para insertarse a tu empresa.',
        a: 'Conoce más'
      },
      card2: {
        title: 'Capacita a tu equipo de tecnología',
        desc: 'Programas en tecnologías como Front End, Full Stack, Java, .Net, Data, Testing, Cloud, Automation, DevOps y herramientas de inteligencia artificial.',
        a: 'Conoce más'
      },
      card3: {
        title: 'Mejora las habilidades de tu equipo',
        desc: 'Programas de upskilling y reskilling <br> disponibles para tu equipo.',
        a: 'Conoce más'
      },
      t5: 'Programas de formación',
      t6: 'PREGUNTAS FRECUENTES',
      p3: 'Conoce nuestros programas pensados especialmente para formar a tu talento actual y futuro, en los lenguajes más requeridos del momento. Nos enfocamos de manera personalizada en las necesidades de tu empresa.',
      p4: 'Utilizamos herramientas de inteligencia artificial para <b>acelerar las habilidades</b> adquiridas en tu equipo.',
      program1: {
        title: 'Desarrollo de Software & ERP',
        tech: '<b>Tecnologías:</b> Java, Spring Boot, Spring Security, REST, RESTful API, AJAX, JSON, Hibernate, Maven/ Gradle, GIT, SQL, Database concepts, Database Relationships, Data Transfer Objects, Conceptos de Cloud.',
        desc: `
          <strong>Front End</strong> <span class="hours">120 - 320 hs.</span> (Js, TypeScript, React, Angular)<br>
          <strong>Back End</strong> <span class="hours">100 - 160 hs.</span> (Java, .Net, Node, Phyton)<br>
          <strong>Full Stack</strong> <span class="hours">600 - 700 hs.</span><br>
          <strong>Testing & Automation</strong> <span class="hours">40 - 160 hs.</span><br>
          <strong>UiPath</strong> <span class="hours">160 hs.</span><br>
          <strong>Software Security</strong> <span class="hours">120 hs.</span>
        `,
        items: [
        {
          "label": "Front End",
          "hours": "120 - 320 hs.",
          "details": "(Js, TypeScript, React, Angular)"
        },
        {
          "label": "Back End",
          "hours": "100 - 160 hs.",
          "details": "(Java, .Net, Node, Phyton)"
        },
        {
          "label": "Full Stack",
          "hours": "600 - 700 hs."
        },
        {
          "label": "Testing & Automation",
          "hours": "40 - 160 hs."
        },
        {
          "label": "UiPath",
          "hours": "160 hs."
        },
        {
          "label": "Software Security",
          "hours": "120 hs."
        }
      ]
      },
      program2: {
        title: 'Data Analytics',
        desc: 'Este programa ha sido diseñado para la formación de Desarrolladores .NET. Este programa está dirigido a personas con conocimientos de programación, estudiantes universitarios de carreras afines a Sistemas que hayan a probado al menos el primer año. Estos programas también están dirigidos a participantes que deban realizar re-skilling y adquirir nuevas capacidades digitales. La metodología de estudio es a través de proyectos reales que van evolucionando en su dificultad hasta adquirir el know how para ser Desarrolladores .Net Jr.',
        tech: '<b>Tecnologías:</b> Visual Studio, C#, .NET Core, Entity Framework Core, SQL Server, JSON, WEB API and RESTful, SASS/ LESS, Azure DevOps Services.',
        items: [
          {
            "label": "Data Visualization",
            "hours": "120 - 160 hs.",
          },
          {
            "label": "Data Engineering",
            "hours": "120 - 160 hs.",
          }
        ]
      },
      program3: {
        title: 'Infraestructura & Cloud',
        items: [
          {
            "label": "DevOps",
            "hours": "90 hs.",
          },
          {
            "label": "Cloud Admin (AWS, Azure, GCP)",
            "hours": "80 hs.",
          }
            ],
        desc: 'Este programa ha sido diseñado para la formación de Desarrolladores Web Front End. Está dirigido a estudiantes universitarios de carreras afines a Sistemas, que hayan aprobado al menos el primer año. Estos programas también están dirigidos a participantes que deban realizar re-skilling y adquirir nuevas capacidades digitales. La metodología de estudio es a través de proyectos reales que van evolucionando en su dificultad hasta adquirir el know how para ser Desarrolladores Web Front End Jr.',
        tech: '<b>Tecnologías:</b> HTML, CCS, JavaScript, JQuery, Ajax, Json, APIs,VUE.js, Bootstrap.'
      },
      program4: {
        title: 'Talento 4.0',
        items: [
          {
            "label": "Ofimática",
          },
          {
            "label": "Productividad y trabajo en equipo",
          },
          {
            "label": "IA aplicada al trabajo",
          },
          {
            "label": "Planillas de cálculo",
          },
          {
            "label": "Comunicación remota",
          }
        ],
        desc: 'Este programa formativo está dirigido a personas con conocimientos de programación, estudiantes universitarios en carreras afines a Sistemas, que hayan aprobado al menos el primer año. Estos programas también están dirigidos a participantes que deban realizar re-skilling y adquirir nuevas capacidades digitales. | Deben acreditar conocimientos en HTML, CSS, JavaScript o haber realizado el bootcamp de Front End.',
        tech: '<b>Tecnologías:</b> MongoDB, Express, React, Node.js, Redux, ES6, Javascript, Login, UI dinámicas, APIS, REST/CRUD, GIT, Post-man, Funcionalidades e-commerce, React Native, Swift,Kotlin, Selenium'
      },
      program5: {
        title: 'MERN <br> Immersion',
        desc: 'Este programa formativo está dirigido a personas con conocimientos de programación, estudiantes universitarios en carreras afines a Sistemas, que hayan aprobado al menos el primer año. Estos programas también están dirigidos a participantes que deban realizar re-skilling y adquirir nuevas capacidades digitales. | Deben acreditar conocimientos en HTML, CSS, JavaScript o haber realizado el bootcamp de Front End.',
        tech: '<b>Tecnologías:</b> MongoDB, Express, React, Node.js, Redux, ES6, Javascript, Login, UI dinámicas, APIS, REST/CRUD, GIT, Postman, Funcionalidades e-commerce.'
      },
      program6: {
        title: 'React Native <br> Immersion',
        desc: 'Este programa formativo está dirigido a personas con conocimientos de programación, estudiantes universitarios en carreras afines a Sistemas, que hayan aprobado al menos el primer año. Estos programas también están dirigidos a participantes que deban realizar re-skilling y adquirir nuevas capacidades digitales. | Deben haber realizado MERN IMMERSION o acreditar conocimientos en React.Js, Node.Js, Express, MongoDB.',
        tech: '<b>Tecnologías:</b> React Native, Swift, Kotlin, Selenium'
      },
      program7: {
        title: 'Data Analytics <br> Initial Immersion',
        desc: 'Este programa está dirigido a Analistas Senior y Semi-Senior, con experiencia comprobada en Sistemas, que ya tengan conocimientos en SQL y programación estructurada y quieran introducirlos en los conocimientos y herramientas para la Ingeniería de Datos.',
        tech: '<b>Tecnologías:</b> Python, Git, Bash, Docker y PostgreSQL, Airflow, Hadoop con HDInsight, ETL y ML con PySpark, Stream Processing.'
      },
      program8: {
        title: 'Data Analytics <br> Advanced Immersion',
        desc: 'Este programa está dirigido a Analistas Senior y Semi-Senior, con experiencia comprobada en Sistemas, que ya tengan conocimientos en SQL, Bash, Docker y PostgreSQL y programación estructurada o que hayan realizado el programa de Data Analytics Academy y quieran introducirlos en los conocimientos y herramientas para la Ingeniería de Datos.',
        tech: '<b>Tecnologías:</b> Azure Stack, Azure Databricks, Data Factory,Sequel Server, ETL, Power BI.'
      },
      program9: {
        title: 'Programa de Certificación <br> Consultor Administrador Salesforce',
        desc: 'Programa destinado a generar habilidades de Configuración en Salesforce, mediante la adquisición de conocimientos básicos de Base de Datos, conceptos esenciales de administración de Lightning Experience y principales funcionalidades de Ventas, Servicios y Colaboración de Salesforce. Adicionalmente, mediante bootcamps se profundizan los conocimientos de Administración, Ventas y Servicios enfrentando requerimientos de clientes a resolver, tal como se presentarán en un proyecto real.',
        tech: '<b>Tecnologías:</b> Salesforce, Database Concepts, Database Relationships. Incluye Certificación Oficial Administrador Salesforce.'
      },
      program10: {
        title: 'Programa de Certificación <br> Desarrollador Salesforce',
        desc: 'Programa destinado a generar habilidades de Desarrollo en Salesforce, mediante la adquisición de conocimientos básicos de Administración Salesforce para luego incorporar conceptos clave del lenguaje de programación Apex y de marcado de Visualforce con los que podrá personalizar las aplicaciones de Salesforce. Adicionalmente, mediante el bootcamp se profundizan los conocimientos de desarrollo Salesforce enfrentando requerimientos de clientes a resolver.',
        tech: '<b>Tecnologías:</b> Salesforce, Java. Incluye Certificación Oficial Desarrollador Salesforce.'
      },
      program11: {
        title: 'Diseño UX/UI </br> Immersion',
        desc: 'Este programa está dirigido a personas sin experiencia previa, que puedan tener o no conocimientos sociales (antropólogos, comunicadores sociales, sociología, etc), de diseño(gráfico, industrial, web, audiovisual, etc.) y desarrolladores que quieran incursionar en la experiencia de usuario. Este curso los formará para ser Diseñadores UX/UI.',
        tech: '<b>Tecnologías:</b> Adobe XD, Figma, Miro, Mural.'
      },
      program12: {
        title: 'Cloud </br> Immersion',
        desc: 'Este programa de formación está dirigido a egresados o estudiantes universitarios, los cuales ya poseen conocimientos en SQL y programación estructurada y se los quiere introducir en los conocimientos y herramientas Cloud.',
        tech: '<b>Tecnologías:</b> Cloud Security, Design for Failure, Infrastructure Automation, Cloud Performance, Serverless, Cost Optimization.'
      },
      program13: {
        title: 'Devops </br> Immersion',
        desc: 'Este programa de formación está dirigido a egresados o estudiantes universitarios, los cuales ya poseen conocimientos en SQL y programación estructurada y se los quiere introducir en los conocimientos y herramientas DevOps.',
        tech: '<b>Tecnologías:</b> Containers y Kubernetes, Builds and Continuous integration, SonarQube, Selenium (Quality Assurance in software testing), Artifactory.'
      },
      program14: {
        title: 'Node.js </br> Immersion',
        desc: 'Este programa ha sido diseñado para la formación de Desarrolladores Node.js. Está dirigido a personas con conocimientos de programación, estudiantes universitarios de carreras afines a Sistemas, que hayan aprobado al menos el primer año. Estos programas también están dirigidos a participantes que deban realizar re-skilling y adquirir nuevas capacidades digitales. La metodología de estudio es a través de proyectos reales que van evolucionando en su dificultad hasta adquirir el know how para ser Desarrolladores Node js.',
        tech: '<b>Tecnologías:</b> Node.js, GIT, Linting, JavaScript Avanzado, Destructuring, Ecmascript, Async Await, Blocking y Non Blocking I/O, Postman, MongoDB, Express, Jest, Passport y JWT, Callbacks, Promesas y CRUD.'
      },
      program15: {
        title: 'Angular </br> Immersion',
        desc: 'Este programa ha sido diseñado para la formación de Desarrolladores Angular. Está dirigido a estudiantes universitarios de carreras afines a Sistemas, que hayan aprobado al menos el primer año. Estos programas también están dirigidos a participantes que deban realizar re-skilling y adquirir nuevas capacidades digitales. La metodología de estudio es a través de proyectos reales que van evolucionando en su dificultad hasta adquirir el know how para ser Desarrolladores Angular.',
        tech: '<b>Tecnologías:</b> Angular, Angular CLI, Angular API, Angular Material, Angular Service, Angular Snippets, CRUD, CSS, SASS, Spa, TypeScript, GIT, Swagger, PIPE de Angular.'
      },
      newtag: 'NUEVO',
      infoBTN: 'MÁS INFORMACIÓN',
      h: 'hs',
      download: 'Descargar <br> programa',
      button2: 'PREGUNTAS FRECUENTES',
      button3: 'CONOCE NUESTROS PROGRAMAS',
      multi: {
        title: 'Capacitación Multiempresas',
        desc: 'Suma talento en nuestras capacitaciones multiempresas. Si no tienes la necesidad de formar 1 cohort entero con profesionales de tu empresa, puedes sumarte a nuestros programas con alumnos de varias empresas según la planificación propuesta.',
        duration: 'DURACIÓN',
        modality: 'MODALIDAD',
        date: 'FECHA DE INICIO',
        hours: 'HORAS',
        button: 'SUMATE A NUESTRO PROGRAMA'
      },
      pyme: {
        title: 'BENEFICIOS <br> PARA PYMES ARGENTINAS <br> Y EMPRESAS CHILENAS',
        desc: '<strong>Argentina:</strong> Si tu empresa se encuentra enmarcada dentro de la clasificación PyME, te informamos que MindHub es una Unidad Capacitadora del "Programa de Crédito Fiscal para Capacitación", que permite a las PyMEs acceder a un reintegro de hasta 100% de los aranceles invertidos en capacitación.',
        desc2: '<strong>Chile:</strong> Si tu empresa se encuentra en Chile, te informamos que MindHub es OTEC, pudiendo brindar cursos que te permitan hacer uso del beneficio de franquicia tributaria de Sence.',
        title2: 'Para obtener mayor información consultar en'
      },
      faqs: {
        question: [
          // "¿Dónde encuentro todos los programas disponibles?",
          "¿Puedo desarrollar un programa personalizado para mi equipo?",
          "¿Para quiénes están destinados estos programas?",
          "¿Qué modalidades de cursada están disponibles?",
          "Beneficios para empresas argentinas",
          "Beneficios para empresas chilenas",
          "Beneficios para empresas colombianas"
        ],
        answer: [
          // "Puedes encontrar toda la información disponible haciendo click en el botón superior 'Descarga los programas empresariales'.",
          "En MindHub podemos desarrollar o adaptar un programa personalizado para tu empresa. Contáctate vía mail a empresas@mindhubweb.com para más información.",
          "Los programas de formación corporativos están destinados para nuevos o futuros colaboradores de áreas IT (new joiners), staff de áreas IT y otras áreas que requieran capacitaciones en tecnología.",
          "Los programas se brindan de manera sincrónica, remotos y en la modadlidad que mejor se ajuste a tu empresa.",
          "Si tu empresa se encuentra enmarcada dentro de la clasificación PyME, te informamos que MindHub es una Unidad Capacitadora del 'Programa de Crédito Fiscal para Capacitación', que permite a las PyMEs acceder a un reintegro de hasta 100% de los aranceles invertidos en capacitación.",
          "Si tu empresa se encuentra en Chile, te informamos que MindHub es OTEC, pudiendo brindar cursos que te permitan hacer uso del beneficio de franquicia tributaria de SENCE.",
          "De conformidad con el numeral 6 del artículo 476 del Estatuto Tributario, los servicios de educación virtual para el desarrollo de contenidos digitales que presta MindHub en Colombia están excluidos del impuesto sobre las ventas – IVA. Este beneficio dependerá del tipo de contenido que se contrate."
        ]
      },
      button4: 'Para más información, ponte en contacto con nosotros',
      button5: 'Ver más'
    },
    press: {
      banner: {
        title: 'MindHub en los medios',
        button: 'IR A NOTAS'
      },
      title: 'MindHub en los medios',
      desc: 'En esta sección podrás ver y escuchar las diferentes entrevistas y notas referidas a nuestra empresa.',
      title2: 'MEDIOS DIGITALES, | RADIO Y PODCAST',
      note: 'Leer nota completa',
      podcast: 'Escuchar el podcast',
      interview: 'Ir a la entrevista'
    },
    testimonials: {
      title: 'Testimonios',
      t1: 'En mi camino por MindHub aprendí no solo lo técnico que esta en su programa si no a desenvolverme mas, a conocerme y conocer mas personas. El método de enseñanza me parece muy efectivo, y los mentores fueron claros y atentos, si tenes alguna duda ellos te ayudan en todo lo que puedan. El boosting que es la preparación para conseguir trabajo es excelente, nos enseñan todo sobre el tema para llegar preparados a esa instancia. Yo recomiendo que probes el bootcamp.',
      t2: 'En mi experiencia en el bootcamp aprendí no solo lo técnico sino habilidades blandas. El método de enseñanza me parece muy efectivo, y los mentores fueron claros y atentos. La preparación para conseguir trabajo es excelente, nos enseñan todo sobre el tema para llegar preparados a esa instancia, lo recomiendo!',
      t3: 'Mi nombre es Federico y mi experiencia por MindHub fue excelente, los mentores son super copados, están siempre dispuestos a ayudar, entran a los canales a ver cómo vas, te preguntan si tenés dudas, y si las hay, se ponen a explicar, dan ejemplos, te muestran cómo podés encarar el problema, te muestran webs donde más gente tuvo ese problema y cómo lo resolvieron, básicamente te incentivan a investigar.'
    },
    formgeneric: {
      first_name: 'Nombre *',
      last_name: 'Apellido *',
      email: 'Correo electrónico *',
      phone: 'Teléfono *',
      country: 'País *',
      city: 'Ciudad *',
      send: 'Enviar',
      sending: 'Enviando...',
      iwantinfot1: '¿Quieres cambiar tu futuro y empezar una carrera en tecnología?',
      iwantinfot2: 'Contáctate con nosotros y conoce nuestra oferta en cursos de programación',
      download: 'Descargar programa',
      validation: {
        first_name: 'Nombre es un campo requerido',
        last_name: 'Apellido es un campo requerido',
        country: 'País es un campo requerido',
        city: 'Ciudad es un campo requerido',
        email: 'Correo electrónico invalido',
        phone: 'Teléfono no es válido'
      },
      toast: {
        t1: "Gracias por enviar el formulario",
        t2: "En breve recibirás un correo con información de nuestros programas.",
        t3: "Recuerda que puedes conocer más de MindHub en nuestro"
      }
    }
  },
  en: {
    error404: {
      title: "404 - Page not found",
      subtitle: "Sorry, the page you are looking for does not exist.",
      buttonLabel: "Go to the home page"
    },
    navblack: {
      p1: 'NEXT BOOTCAMP',
      p2: 'APPLY NOW'
    },
    navwhite: {
      navitem1: {
        p1: 'WE ARE',
        p2: 'MINDHUB'
      },
      navitem2: {
        p1: 'WEB',
        p2: 'DEVELOPMENT'
      },
      navitem3: {
        p1: 'MINDHUB',
        p2: 'STUDENTS'
      },
      navitem4: {
        p1: 'MINDHUB',
        p2: 'COMPANIES'
      },
      navitem5: {
        p1: 'APPLY',
        p2: 'NOW'
      }
    },
    homebanner: 'WE TRAIN THE IT PROFESSIONALS OF THE FUTURE | Our IT programs prepare you for the new digital era ',
    aplicar: {
      t1: 'How to apply',
      p1: 'Choose your preferred bootcamp | (Full Stack Java / Full Stack | Mern) ',
      p2: 'Sign up to be the | owner of your future',
      p3: 'Study and pass | "My First Website" | (64hrs)',
      p4: 'Take you chosen | bootcamp',
      p5: 'We help you build | your work profile',
      p6: 'Get a job | and be happy!',
      insc: 'SIGN UP'
    },
    bootcamp: {
      t1: 'Our Bootcamps',
      p1: 'Our study modality adapts to your needs. At MindHub you can choose | to train full time, part time, 100% face-to-face, virtual or mixed.',
      mpsw: 'My first | website',
      mpswP: 'Dare to take the first step in the world of development. You will learn HTML and CSS from scratch to build your first website. Once you have completed this program, you will continue with the chosen bootcamp.',
      how: 'How do I apply?',
      java: 'Full Stack JAVA | Immersive Bootcamp',
      javaDescription: "You will learn how to design and develop complete websites, creating a dynamic user interface using front-end technologies and how to implement services and business logic on the back-end. <br> You will use the JAVA frameworks, such as Spring Boot, which will accelerate your understanding and development of web applications. <br> Learn HTML5, CSS3, Javascript, JAVA and much more.",
      javaP: 'Do you want to master the most demanded programming languages on the market?',
      mern: 'Full Stack REACT JS | Immersive Bootcamp',
      mernDescription: 'You will learn how to design and develop complete web pages from scratch, seamlessly integrating user interfaces on the front-end with robust functionalities on the back-end. <br> You will also create dynamic and responsive user experiences using technologies and programming languages such as HTML5, CSS3, Javascript, MongoDB, Express, React and Node Js.',
      mernP: 'Learn everything about web development and technologies like React Native and Selenium!',
      date: 'Next dates',
      modality: 'Modality',
      duration: 'Duration',
      schedule: 'Schedule',
      signUp: 'I WANT TO SIGN UP',
      signUpNewJava: 'ENROLL IN THE PROGRAM',
      goOpenHouse: 'DISCOVER THE OPEN HOUSE',
      price: 'Price',
      download: 'DOWNLOAD THE BOOTCAMP SYLLABUS',
      opt1: 'Select your country',
      opt2: 'Argentina',
      opt3: 'Chile',
      opt4: 'Colombia',
      opt5: 'Uruguay',
      opt6: 'Rest of the world',
      discount: '20% discount if you pay while you study.',
      footer: 'Both bootcamps are preceded by the initial program My First Website',
      apply: "Apply to the Program",
      applyDescription: "Unlike most intensive programming courses, we do not need you to have any previous knowledge or experience. To participate, you must be over 18 years old, have an identity document from the country where you reside, and a high school diploma.",
      form: "Fill out the form",
      formDescription: "Tell us about yourself, your knowledge, and your interests.",
      challenge: "Complete the MindHub challenge",
      challengeDescription: "You will receive a challenge to solve.",
      blended: "Choose your financing plan",
      blendedDescription: "Start the bootcamp with one of these options: Traditional Payment, Deferred Payment, or Blended Payment.",
      bootcamp: "Complete the bootcamp",
      bootcampDescription: "For 4 months on average, just worry about learning.",
      financing: "Financing",
      financingDescription: "If you choose a financing option, pay only when you get a job with a salary equal to or greater than that of a Jr. Developer in your country of residence.",
      description: "Tell us about yourself and your desire to become a developer, complete the challenge and you're done. It only remains to wait and see if you are part of this brood of Hubbers where we will assemble diverse groups that can form a true work team.",
      title: "WHERE DO OUR HUBBERS WORK?",
      brandTitle: "Our Ecosystem",
      train: " We trained more than 1,500 boys and girls in technology. Our Hubbers who graduated from our Full Stack bootcamps, today work as Full Stack Developers in leading companies and organizations.",
      join: "Do you want to join them?",
    },
    modalities: {
      a_distancia: 'Remote',
      blended: 'Blended',
      presencial: 'Face-to-face',
      virtual_en_vivo: 'Live virtual'
    },
    pagos: {
      title: 'Payment methods by country',
      header1: 'Payment method available',
      header2: 'Argentina',
      header3: 'Chile',
      header4: 'Colombia',
      header5: 'Rest of America and the world',
      item1_header1: 'TRADITIONAL PAYMENT',
      item1_header1_part2: '[Pay while you study with a discount]',
      item2_header1: 'DEFERRED PAYMENT',
      item2_header1_part2: '[Pay when you get a job]',
      item3_header1: 'BLENDED PAYMENT',
      item3_header1_part2: '[Combine the first options]',
      item4_header1: 'PAY IN INSTALLMENTS',
      item4_header1_part2: '[12 discounted installments]'
    },
    openhouse: {
      t1: 'Learn more at the OPEN HOUSE',
      p: 'An informal chat where you will be able to learn about experiences and how is the day to day life in the Bootcamp.',
      tdate: 'NEXT DATE',
      btn: 'SIGN UP FOR THE OPEN HOUSE',
      data: 'NOT AVAILABLE',
    },
    date: {
      day: {
        0: 'Sunday,',
        1: 'Monday,',
        2: 'Tuesday,',
        3: 'Wednesday,',
        4: 'Thursday,',
        5: 'Friday,',
        6: 'Saturday,',
      },
      month: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
    },
    carousel: {
      c1: 'Learn Full Stack <br> programming in 4 months',
      c2: 'More than 5000 graduates',
      c3: 'Our bootcamps simulate a <br> work environment from day one',
      c4: 'Learn the most demanded <br> programming skills in the IT market',
      c5: 'Start paying only <br> when you get a job',
    },
    carouselEmpresa: {
      c1: 'Technology training for companies',
      c2: 'Reskilling and Upskilling for employees',
      c3: 'Scholarship programs for IT training',
      c4: 'Data, .Net, Java, Cloud, Full Stack',
    },
    carousel2: {
      c1: 'We are the best-ranked bootcamp <br>in Latin America',
      c2: 'News'
    },
    footer: {
      t1: 'Do you want to be part of our community?',
      p1: 'We are going to tell you about our events, new workshops and all the news',
      placeholder: 'Name',
      p2: 'How do you prefer that we contact you?',
      t2: 'Do you want to work | at MindHub?',
      button: 'SEND',
      a: 'Send us your Resume',
      t3: 'Terms | and conditions',
      li1: 'FAQ',
      li2: 'TERMS',
      t4: 'Contact',
      tg: 'We are MindHub, we train | the talents of the new digital era.',
      p3: '*This website was developed by MindHub alumni using | HTML, CSS, JavaScript, Vue and Bootstrap content, learned in our bootcamps.',
    },
    empresas: 'Get your first job in technology in the best companies',
    inscribete: {
      t1: 'Are you ready to join the revolution and start your digital career with us?',
      tb: 'SIGN UP',
      in1: 'Name',
      in2: 'E-mail',
      in3: 'City',
      in4: 'Message',
    },
    testimonial: {
      p1: '"At MindHub they train you to be prepared when entering the world of work with tech and soft skills. They are very attentive to your progress. I left with triple the knowledge"',
      p2: 'Gabriela Sosa',
      t1: 'Your digital | career starts | now: ',
      t2: 'take your | future to another | level!',
    },
    somosmh: {
      t1: 'At MindHub, we train the digital talents of the future',
      p1: 'At MindHub, we integrate education with employment through intensive programs in digital technologies.',
      p2: 'We train students and company staff in digital skills by providing quality education, aligned with the United Nations Sustainable Development Goals.',
      p3: 'Are you looking to improve your digital skills and land a job in the industry?',
      t2: 'Your journey as a Hubber can start today',
      vision: {
        title: 'OUR RESULTS',
        body: 'To be a leader in developing digital talent for | employability in the new economy.'
      },
      mission: {
        title: 'Our Mission',
        body: 'To develop market-relevant skills | so that people can | access a better future.'
      },
      founders: 'OUR FOUNDERS',
      p33: "25 years of experience in the IT industry.",
      p34: "+20 years at Accenture as Managing Directors with various regional responsabilities.",
      p35: "6 years leading MindHub, a high-growth educational startup in Latin America.",
      t3: 'OUR MARKET',
      p4: 'To achieve success it is not enough to have passion and a mission, | It is also essential that the group of people| who are beside you have excellent professionalism and human values.',
      p5: 'our team wouldn\'t be complete without a long list of people who helped us create our own | butterfly effect.',
      p6: 'The reason why MindHub is possible is thanks to countless friends, colleagues, and mentors who were by our side from the beginning, dedicating their time and experience to us and contributing their ideas and creativity. Those small gestures had a great influence on our future.',
      p7: 'Those small gestures had a great influence on our future.',
      p8: 'That is why we don\'t want to miss the opportunity to say THANK YOU to all of them for being key members of this great team.',
      p9: 'Together | we began to operate in major countries of Latin America in 2018.',
      p10: 'Together | we won the award for the best new venture in 2019 (chosen by the main business schools of Argentina).',
      p11: 'Together|, we were also awarded for our Gender Equity initiative by the Citi Foundation and the Rockefeller Foundation.',
      p12: 'Together | we went international in 2020 and reached the first 1,000 students – the first of many more to come.',
      p13: 'As a team, we are moved every time our MindHub graduates find a job or tell us how they have changed their life.',
      t4: 'A team with great achievements,| but more than anything else,| great dreams.',
      button: 'Contact Us',
      nestrosmercados: {
        card1: {
          title: 'FUTURE<br> PROGRAMMERS',
          desc: 'We bring people closer to the world of programming through:<br> <br>- Synchronous, intensive Full Stack<br> programming programs in JAVA and REACT JS.<br>-Financing options for IT education programs.'
        },
        card2: {
          title: 'COMPANIES',
          desc: 'We help enhance the technical<br> skills of collaborators through:<br><br>- IT training programs for new joiners.<br>- Upskilling and reskilling programs<br> designed for IT staff or other functional areas.'
        },
        card3: {
          title: 'IT TRAINING SCHOLARSHIPS',
          desc: 'We work with Governments, Business<br>Chambers, NGOs and companies to:<br> <br>- Articulate scholarship programs<br> for vulnerable populations.<br>- Democratize the access<br> to technology education.'
        },
      }
    },
    nuestrosboot: {
      t1: 'Are you ready to join the revolution and start your digital career with us?',
      p1: 'At MindHub we use the "Learning by doing" methodology, which will allow you to achieve high employability.In each bootcamp, we simulate a work environment where we set up innovative projects so that you can reach an advanced level in the field, without having previous knowledge. Our mentors and supervisors will be by your side throughout the whole process, helping you identify which areas need improvement in real-time and adjusting the methodology to your needs.',
      p2: '5 years',
      p3: 'training | digital | talent'
    },
    financing: {
      title: 'Financing Alternatives',
      desc: 'We offer different alternatives so that your learning journey is |easier and in alignment with your personal financial situation.',
      trad: {
        title: 'Traditional payment',
        desc: 'Pay while you study',
        p: 'Pay traditionally at the beginning of the bootcamp (after having completed "My First Website"), in one or more installments with attractive discounts.'
      },
      work: {
        title: 'Deferred payment',
        desc: 'Pay while you are working',
        p: 'Start paying while you are working with an income equivalent to that of a Jr. Developer.  Once you have successfully completed "My First Website", you will sign a Future Income Agreement equivalent to the amount of the Bootcamp in order to pay the full cost in installments.'
      },
      blend: {
        title: 'Blended payment',
        desc: 'Build your own payment plan',
        p: 'If you want to pay part of the bootcamp and postpone the rest of the payment until you are working, we can design a plan that adapts to your capabilities.'
      },
    },
    conoce: {
      title: 'Do you want to know how | is a day at MindHub?',
      subtitle: 'A virtual journey on the path that students go through.',
      card1: {
        title: 'Daily Meeting',
        body: 'The day begins with a daily meeting between the students and the mentors, where they review the progress of the work carried out the previous day, the difficulties that hindered their development, and the tasks to take on that day.',
        hours: '9 hrs.'
      },
      card2: {
        title: 'Daily Workshop',
        body: 'At midmorning, the mentors carry out their daily workshops where they expose the new topics and concepts to face the task of the day.',
      },
      card3: {
        title: 'Mentor 1:1',
        body: 'The morning continues with 1:1 meetings between the students and their mentors, clearing up doubts to be able to move forward with the projects.',
      },
      card4: {
        title: 'Code Check',
        body: 'Before lunch, the participants explain and answer questions about their code. Developments are reviewed to meet industry standards.',
        hours: '13 hrs.'
      },
      card5: {
        title: 'Lunch',
      },
      card6: {
        title: 'Game & Learn',
        body: 'Once a week, the afternoon begins with a recreational activity where, through gamification, students can consolidate the theoretical knowledge learned.',
        hours: '14 hrs.'
      },
      card7: {
        title: 'Pair Programming & Pair Reviews',
        body: 'Then, it\'s time to leap into working on the code again through activities where peer work is encouraged. | * These activities will have the assistance of your mentors so that you can move forward smoothly.',
      },
      card8: {
        title: 'Retro Meeting',
        body: 'The day ends with a Retro Meeting where the progress of the day is reviewed by comparing it to the work plan. Actions are taken to start the next day energized.',
      },
      banner1: 'A learning ecosystem <br> focused on your future',
      p1: 'We don\'t want to lock you in a classroom but to open the doors to the labor market <br>How? Making you part of an ecosystem of continuous learning in which your future is the main focus.',
      icon1: {
        title: 'Learn',
        desc: 'Acquire knowledge, languages, and new ways of thinking.'
      },
      icon2: {
        title: 'Experience',
        desc: 'Put what you have learned into practice, with 1:1 feedback from your mentor.'
      },
      icon3: {
        title: 'Work',
        desc: 'Solve real problems for customers all over the world.'
      },
      icon4: {
        title: 'Follow your own path',
        desc: 'Obtain a place in the most important companies in the industry.'
      }
    },
    eresempresa: {
      t1: 'Do you need to recruit <br> or reskill <br> digital talent <br> in your company?',
      t2: 'AT MINDHUB WE EMPOWER YOUR TEAM',
      p1: 'Improve your employees performance with our technology trainings.',
      button1: 'HIRE OUR GRADUATES',
      t3: 'What can we offer you?',
      t4: 'Our ecosystem',
      headerCard: {
        title: 'Professionals who are ready</br> to join your company',
        desc: 'Our graduates spend 600-800 hours simulating a real work environment, which allows them to join development teams efficiently from the very beginning.'
      },
      card1: {
        title: 'Hire Full Stack developers',
        desc: 'We have Full Stack developers <br> ready to join your company.',
        a: "Learn more"
      },
      card2: {
        title: 'Train your IT team',
        desc: 'Programs in technologies such as Front End, Full Stack, Java, .Net, Data, Testing, Cloud, Automation, DevOps, and artificial intelligence tools.',
        a: "Learn more"
      },
      card3: {
        title: "Improve your team's skills",
        desc: 'Upskilling and reskilling programs <br> available for your team.',
        a: "Learn more"
      },
      t5: 'IT education programs',
      p3: 'Learn about our programs specially designed to train your existing and future talent in the most required languages and technologies of the moment. We focus on the needs of your company in a personalized manner.',
      p4: "We use artificial intelligence tools to accelerate your team's skills.",
      program1: {
        title: 'Software Development & ERP',
        tech: '<b>Technologies:</b> Java, Spring Boot, Spring Security, REST, RESTful API, AJAX, JSON, Hibernate, Maven/ Gradle, GIT, SQL, Database concepts, Database Relationships, Data Transfer Objects, Cloud Concepts.',
        desc: `
        <strong>Front End</strong> <span class="hours">120 - 320 hs.</span> (Js, TypeScript, React, Angular)<br>
        <strong>Back End</strong> <span class="hours">100 - 160 hs.</span> (Java, .Net, Node, Phyton)<br>
        <strong>Full Stack</strong> <span class="hours">600 - 700 hs.</span><br>
        <strong>Testing & Automation</strong> <span class="hours">40 - 160 hs.</span><br>
        <strong>UiPath</strong> <span class="hours">160 hs.</span><br>
        <strong>Software Security</strong> <span class="hours">120 hs.</span>
      `,
      items: [
        {
          "label": "Front End",
          "hours": "120 - 320 hs.",
          "details": "(Js, TypeScript, React, Angular)"
        },
        {
          "label": "Back End",
          "hours": "100 - 160 hs.",
          "details": "(Java, .Net, Node, Phyton)"
        },
        {
          "label": "Full Stack",
          "hours": "600 - 700 hs."
        },
        {
          "label": "Testing & Automation",
          "hours": "40 - 160 hs."
        },
        {
          "label": "UiPath",
          "hours": "160 hs."
        },
        {
          "label": "Software Security",
          "hours": "120 hs."
        }
      ]
      
      },
      program2: {
        title: 'Data Analytics ',
        items: [
        {
          "label": "Data Visualization",
          "hours": "120 - 160 hs.",
        },
        {
          "label": "Data Engineering",
          "hours": "120 - 160 hs.",
        }
          ],
        desc: '-Data Visualization       120 - 160 hs.<br>-Data Engineering         120 - 160 hs.',
        tech: '<b>Technologies:</b> Visual Studio, C#, .NET Core, Entity Framework Core, SQL Server, JSON, WEB API and RESTful, SASS/ LESS, Azure DevOps Services.'
      },
      program3: {
        title: 'Infrastructure & Cloud',
        items: [
          {
            "label": "DevOps",
            "hours": "90 hs.",
          },
          {
            "label": "Cloud Admin (AWS, Azure, GCP)",
            "hours": "80 hs.",
          }
            ],
        desc: 'This program was designed for the training of Front-End Web Developers. It is aimed at university students majoring in Systems Engineering or related fields who have passed at least the first year. These programs are also aimed at participants who must engage in re-skilling activities and acquire new digital abilities. Participants are involved in real projects that become more difficult with time until they acquire the know-how to be Front-End Web Jr. Developers.',
        tech: '<b>Technologies:</b> HTML, CCS, JavaScript, JQuery, Ajax, Json, APIs,VUE.js, Bootstrap.'
      },
      program4: {
        title: 'Talent 4.0',
        items: [
          {
            "label": "Office automation",
          },
          {
            "label": "Productivity and teamwork",
          },
          {
            "label": "AI for Business",
          },
          {
            "label": "Spreadsheets",
          },
          {
            "label": "Remote communication",
          }
        ],
        desc: 'This training program is aimed at people with programming knowledge and university students majoring in Systems Engineering or related fields who have passed at least the first year. These programs are also aimed at participants who must engage in re-skilling activities and acquire new digital abilities. These programs are also aimed at participants who must perform re-skilling and acquire new digital capabilities. | They must prove knowledge in HTML, CSS, JavaScript, or completion of the Front-End bootcamp.',
        tech: '<b>Technologies:</b> MongoDB, Express, React, Node.js, Redux, ES6, Javascript, Login, dynamic UI, APIS, REST/CRUD, GIT, Post-man, e-commerce functionalities, React Native, Swift,Kotlin, Selenium'
      },
      program5: {
        title: 'MERN <br> Immersion',
        desc: 'This training program is aimed at people with programming knowledge and university students majoring in Systems Engineering or related fields who have passed at least the first year.  These programs are also aimed at participants who must engage in re-skilling activities and acquire new digital abilities. | They must prove knowledge in HTML, CSS, JavaScript, or completion of the Front-End bootcamp.',
        tech: '<b>Technologies:</b> MongoDB, Express, React, Node.js, Redux, ES6, Javascript, Login, dynamic UI, APIS, REST/CRUD, GIT, Postman, e-commerce functionalities.'
      },
      program6: {
        title: 'React Native <br> Immersion',
        desc: 'This training program is aimed at people with programming knowledge and university students majoring in Systems Engineering or related fields who have passed at least the first year. These programs are also aimed at participants who must engage in re-skilling activities and acquire new digital abilities. | They must prove completion of the MERN bootcamp or knowledge in React.Js, Node.Js, Express, and MongoDB.',
        tech: '<b>Technologies:</b> React Native, Swift, Kotlin, Selenium'
      },
      program7: {
        title: 'Data Analytics <br> Initial Immersion',
        desc: 'This program is aimed at Senior and Semi-Senior Analysts, with proven experience in Systems Engineering, who already have knowledge in SQL and structured programming and want to be introduced to the Data Engineering tools.',
        tech: '<b>Technologies:</b> Python, Git, Bash, Docker and PostgreSQL, Airflow, Hadoop with HDInsight, ETL and ML with PySpark, Stream Processing.'
      },
      program8: {
        title: 'Data Analytics <br> Advanced Immersion',
        desc: 'This program is aimed at Senior and Semi-Senior Analysts, with proven experience in Systems Engineering, who already have knowledge in SQL, Bash, Docker, PostgreSQL, and structured programming, or who have completed the Data Analytics Initial Immersion program, and want to be introduced to the Data Engineering tools.',
        tech: '<b>Technologies:</b> Azure Stack, Azure Databricks, Data Factory,Sequel Server, ETL, Power BI.'
      },
      program9: {
        title: 'Salesforce Consultant Admnistrator <br> Certification Program',
        desc: 'This program was designed to generate Configuration skills in Salesforce through the development of basic knowledge of Database, essential concepts of Lightning Experience administration, and main functionalities of Salesforce’s Sales, Services and Collaboration. Additionally, the know-how in Administration, Sales and Services is broadened and applied in Bootcamps, where the participants are presented with customer requirements that need solving as if they were part of a real project.',
        tech: '<b>Technologies:</b> Salesforce, Database Concepts, Database Relationships. Includes Official Salesforce Administrator Certification.'
      },
      program10: {
        title: 'Salesforce Developer <br> Certification Program',
        desc: 'Program designed to generate Development skills in Salesforce, by acquiring basic knowledge of Salesforce Administration and then incorporating key concepts of the Apex programming language and Visualforce markup with which you can customize Salesforce applications. Additionally, through the bootcamp the knowledge of Salesforce development is deepened, facing customer requirements to be solved.',
        tech: '<b>Technologies:</b> Salesforce, Java. Includes Official Salesforce Developer Certification.'
      },

      program11: {
        title: 'UX/UI Design  </br> Immersion',
        desc: 'This program is oriented to people without previous experience that may or may not have social skills (anthropologists, social communicators, sociologists, etc.) or design skills (graphic, industrial, web, audiovisual, etc.) and for developers who want to dabble in user experience. This course will train participants to become UX/UI Designers.',
        tech: '<b>Tecnologías:</b> Adobe XD, Figma, Miro, Mural.'
      },
      program12: {
        title: 'Cloud </br> Immersion',
        desc: 'This training program is oriented to university graduates or students, who already have SQL knowledge and structured programming and want to be introduced to Cloud knowledge and tools.',
        tech: '<b>Tecnologías:</b> Cloud Security, Design for Failure, Infrastructure Automation, Cloud Performance, Serverless, Cost Optimization.'
      },
      program13: {
        title: 'Devops </br> Immersion',
        desc: 'This program is oriented to university graduates or students, who already have SQL knowledge and structured programming and want to be introduced to DevOps knowledge and tools.',
        tech: '<b>Tecnologías:</b> Containers y Kubernetes, Builds and Continuous integration, SonarQube, Selenium (Quality Assurance in software testing), Artifactory.'
      },
      program14: {
        title: 'Node.js </br> Immersion',
        desc: 'This program was designed to train Node.js Developers. The target audience is people with programming knowledge: university students majoring in System Engineering or related fields who have passed at least the first year. The program is also applicable for participants who must engage in reskilling programs and acquire new digital abilities. The learning methodology is based on emulated real projects with incremental difficulty until students acquire the know-how to become Node.js Developers.',
        tech: '<b>Tecnologías:</b> Node.js, GIT, Linting, JavaScript Avanzado, Destructuring, Ecmascript, Async Await, Blocking y Non Blocking I/O, Postman, MongoDB, Express, Jest, Passport y JWT, Callbacks, Promesas y CRUD.'
      },
      program15: {
        title: 'Angular </br> Immersion',
        desc: 'This program was designed to train Angular Developers. It is oriented to university students majoring in System Engineering or related fields who have passed at least the first year. It is also applicable for participants who must engage in reskilling programs and acquire new digital abilities. The learning methodology is based on emulated real projects with incremental difficulty till students acquire the required know-how to become Angular Developers.',
        tech: '<b>Tecnologías:</b> Angular, Angular CLI, Angular API, Angular Material, Angular Service, Angular Snippets, CRUD, CSS, SASS, Spa, TypeScript, GIT, Swagger, PIPE de Angular.'
      },
      newtag: 'NEW',
      infoBTN: 'MORE INFORMATION',
      h: 'hrs',
      download: 'Download <br> program',
      button2: "FAQ's",
      t6: "FAQ's",
      button3: 'DOWNLOAD OUR PROGRAMS BROCHURE',
      multi: {
        title: 'Multi-company Training',
        desc: 'Nurture talent in our multi-company training sessions. If you do not need to train an entire cohort with professionals from your company, you can join our programs with students from several companies according to the suggested plan.',
        duration: 'DURATION',
        modality: 'MODALITY',
        date: 'START DATE',
        hours: 'HOURS',
        button: 'JOIN OUR PROGRAM'
      },
      pyme: {
        title: 'BENEFITS <br> FOR ARGENTINIAN PYMES <br> AND <br> CHILEAN ENTERPRISES',
        desc: '<strong>Argentina:</strong> If your company is framed within the SME classification, we inform you that MindHub is a Training Unit of the "Training Tax Credit Program"; which allows the SMEs have access to a refund of up to 100% of fees invested in training.',
        desc2: '<strong>Chile:</strong> If your company is located in Chile, we inform you that MindHub is OTEC, being able to offer courses that allow you to make use of the tax exemption from Sence.',
        title2: 'For more information consult'
      },
      faqs: {
        question: [
          // "Where do I find all the available programs?",
          "Can I develop a custom program for my team?",
          "Who are these programs intended for?",
          "What course modalities are available?",
          "Benefits for Argentine companies",
          "Benefits for Chilean companies",
          "Benefits for Colombian companies"
        ],
        answer: [
          // "You can find all the available information by clicking on the 'Download the business programs' button above.",
          "At MindHub we can develop or adapt a personalized program for your company. Contact via email at companies@mindhubweb.com for more information.",
          "Corporate training programs are intended for new or future collaborators in IT areas (new joiners), staff in IT areas and other areas that require technology training.",
          "The programs are provided synchronously, remotely and in the manner that best suits your company.",
          "If your company falls within the SME classification, we inform you that MindHub is a Training Unit of the 'Training Tax Credit Program', which allows SMEs to access a refund of up to 100% of the fees invested in training.",
          "If your company is located in Chile, we inform you that MindHub is OTEC, and can provide courses that allow you to use the SENCE tax exemption benefit.",
          "In accordance with numeral 6 of article 476 of the Tax Statute, the virtual education services for the development of digital content provided by MindHub in Colombia are excluded from sales tax – VAT. This benefit will depend on the type of content that is contracted."
        ]
      },
      button4: 'For more information, contact us',
      button5: "See more"
    },
    press: {
      banner: {
        title: 'MindHub in the media',
        button: 'VIEW ARTICLES'
      },
      title: 'MindHub in the media',
      desc: 'In this section, you will be able to watch and listen to the different interviews and articles about our company.',
      title2: 'DIGITAL MEDIA, | RADIO AND PODCAST',
      note: 'Read full article',
      podcast: 'Listen to the podcast',
      interview: 'Listen the interview'
    },
    testimonials: {
      title: 'Testimonials',
      t1: 'During my experience with MindHub I learned not only the technical aspects from their program but also how to develop myself more, to know myself and meet more people. The teaching method seems very effective to me, and the mentors were clear and attentive, if you have any questions they help you in any way they can. The boosting my career module, that is the preparation to get a job is excellent, they teach us everything about the subject to arrive prepared for that instance. I recommend that you try the bootcamp.',
      t2: 'During my bootcamp experience I learned not only technical skills but also soft skills. I found the teaching method very effective, and the mentors were clear and attentive.',
      t3: 'My name is Federico and my experience at MindHub was excellent, the mentors are super cool, they are always willing to help, they get in the channels to see how you are doing, they ask if you have any questions, and if there are any, they start explaining them, giving us examples, showing us websites where other people had the same issue and how they solved it, basically they encourage you to investigate.'
    },
    formgeneric: {
      first_name: 'Name *',
      last_name: 'Last Name *',
      email: 'Email *',
      phone: 'Phone *',
      country: 'Country *',
      city: 'City *',
      send: 'Send',
      sending: 'Sending...',
      iwantinfot1: 'Do you want to change your future and start a career in technology?',
      iwantinfot2: 'Contact us and learn about our selection of programming courses',
      download: 'Download Syllabus',
      validation: {
        first_name: 'Name field is required',
        last_name: 'Last name field is required',
        country: 'Country field is required',
        city: 'City field is required',
        email: 'Invalid email',
        phone: 'Invalid phone number'
      },
      toast: {
        t1: "Thank you for submitting the form",
        t2: "You will soon receive an email with information about our programs.",
        t3: "Remember that you can learn more about MindHub in our"
      }
    }
  }
}

export const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'es',
  fallbackLocale: 'en',
  messages
})