<template>
  <div id="empresas">
    <div id="banner2">
      <h2>{{$t('empresas')}}</h2>
    </div>
    <template v-if="mq3.matches">
    <div id="empresas">
      <b-button type="submit" id="logo1"></b-button>
      <b-button type="submit" id="logo3"></b-button>
      <b-button type="submit" id="logo4"></b-button>
      <b-button type="submit" id="logo5"></b-button>
      <b-button type="submit" id="logo6"></b-button>
      <b-button type="submit" id="logo7"></b-button>
    </div>

    <div id="empresas2">
      <b-button type="submit" id="logo8"></b-button>
      <b-button type="submit" id="logo9"></b-button>
      <b-button type="submit" id="logo10"></b-button>
      <b-button type="submit" id="logo12"></b-button>
      <b-button type="submit" id="logo14"></b-button>
    </div>
    </template>
    <template v-else-if="mq5.matches">
             <b-carousel :interval="2000" class="empresas-carousel">
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/01-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/03-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/12-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/04-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/05-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/06-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/07-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/08-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/09-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/10-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/14-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
              </b-carousel>
              </template>
    <template v-else>
    <b-carousel :interval="2000" class="empresas-carousel">
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/01-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/03-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/04-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/05-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/06-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/07-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/08-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/09-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/10-1.png" alt="empresas">
                              </div>
                              <div>
                                  <img src="../assets/logos/12-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
                  <b-carousel-slide>
                      <template slot="img">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <img src="../assets/logos/14-1.png" alt="empresas">
                              </div>
                          </div>
                      </template>
                  </b-carousel-slide>
              </b-carousel>
    </template>
  </div>
</template>
<style scoped>
#empresas{
  background-color: white;
  padding: 20px 0;
}

#logo1 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/01-2.png");
  background-size: cover;
  background-position: center;
}
#logo3 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/03-2.png");
  background-size: cover;
  background-position: center;
}
#logo4 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/04-2.png");
  background-size: cover;
  background-position: center;
}
#logo5 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/05-2.png");
  background-size: cover;
  background-position: center;
}
#logo6 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/06-2.png");
  background-size: cover;
  background-position: center;
}
#logo7 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/07-2.png");
  background-size: cover;
  background-position: center;
}
#logo8 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/08-2.png");
  background-size: cover;
  background-position: center;
}
#logo9 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/09-2.png");
  background-size: cover;
  background-position: center;
}
#logo10 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/10-2.png");
  background-size: cover;
  background-position: center;
}
#logo12 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/12-2.png");
  background-size: cover;
  background-position: center;
}
#logo14 {
  width: 146px;
  height: 94px;
  border: 0px;
  background-color: transparent;
  background-image: url("../assets/logos/14-2.png");
  background-size: cover;
  background-position: center;
}
#logo1:hover {
  background-image: url("../assets/logos/01-2.png");
}
#logo3:hover {
  background-image: url("../assets/logos/03-2.png");
}
#logo4:hover {
  background-image: url("../assets/logos/04-2.png");
}
#logo5:hover {
  background-image: url("../assets/logos/05-2.png");
}
#logo6:hover {
  background-image: url("../assets/logos/06-2.png");
}
#logo7:hover {
  background-image: url("../assets/logos/07-2.png");
}
#logo8:hover {
  background-image: url("../assets/logos/08-2.png");
}
#logo9:hover {
  background-image: url("../assets/logos/09-2.png");
}
#logo10:hover {
  background-image: url("../assets/logos/10-2.png");
}
#logo12:hover {
  background-image: url("../assets/logos/12-2.png");
}
#logo14:hover {
  background-image: url("../assets/logos/14-2.png");
}

#banner2 {
  padding-top: 5%;
  padding-bottom: 3%;
  margin-right: 19%;
  margin-left: 19%;
}
#banner2 h2 {
  text-align: center;
  color: black;
  font-size: 1.5rem;
  font-weight: 900;
}
@media screen and (min-width: 768px) {
  #banner2 h2 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  #banner2 h2 {
    font-size: 2.5rem;
  }
}
#empresas button {
  display: inline;
  padding: 1%;
  margin: 0 20px;
}
#empresas2 button {
  display: inline;
  padding: 1%;
  margin: 0 20px;
}
@media screen and (max-width: 768px) {
  .empresas-carousel img{
      width: 100px;
  }
}

@media screen and (max-width: 425px) {
  .empresas-carousel img{
      width: 50px;
  }
}

</style>
<script>
export default {
  name: "Empresas",
  data(){
    return{
      mq3: window.matchMedia('(min-width: 1350px)'),
      mq5: window.matchMedia('(min-width: 768px)')
      
    }
  },
  beforeMount(){
    window.addEventListener('resize', () => this.mq3 = window.matchMedia('(min-width: 1350px)'))
    window.addEventListener('resize', () => this.mq5 = window.matchMedia('(min-width: 768px)'))
  }
}

</script>