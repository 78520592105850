<template>
  <div
    v-bind:class="{
      'form-container': true,
      'form-container-quiero-info': this.leadSource === 'Website - Quiero Info B2C',
      'form-container-java': this.leadSource === 'Website - Syllabus B2C Java',
      'form-container-mern': this.leadSource === 'Website - Syllabus B2C MERN',
    }"
  >
    <div v-if="leadSource === 'Website - Quiero Info B2C'" class="info-text">
      <h4>{{ $t("formgeneric.iwantinfot1") }}</h4>
      <h4>{{ $t("formgeneric.iwantinfot2") }}</h4>
    </div>
    <b-form
      v-bind:class="{ 'half-width': leadSource === 'Website - Quiero Info B2C' }"
      id="form"
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D8c000001jtTO"
      method="POST"
    >
      <b-container>
        <b-form-input required name="oid" value="00D8c000001jtTO" hidden />
        <b-form-input
          required
          name="retURL"
          :value="retURL"
          hidden
        /> 
 
        <!--  ----------------------------------------------------------------------  -->
        <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
        <!--  these lines if you wish to test in debug mode.                          -->
        <!--  <input type="hidden" name="debug" value=1>                              -->
        <b-form-input
          required
          name="debugEmail"
          value="mindhub@vurpix.com"
          hidden
        />

        <b-form-input
          required
          id="recordType"
          name="recordType"
          value="0128c000002FKhq"
          hidden
        />

        <b-form-select
          required
          id="lead_source"
          name="lead_source"
          v-model="leadSource"
          :options="leadSourceOptions"
          hidden
        ></b-form-select>

        <!-- Campos de información del contacto -->

        <b-form-input
          required
          v-model="first_name"
          @input="validateFirstName"
          id="first_name"
          maxlength="40"
          name="first_name"
          size="20"
          type="text"
          v-bind:placeholder="$t('formgeneric.first_name')"
        ></b-form-input>
        <p class="error">{{ validationErrors.first_name }}</p>
        <p v-if="!validationErrors.first_name" class="blank-p"></p>

        <b-form-input
          required
          v-model="last_name"
          @input="validateLastName"
          id="last_name"
          maxlength="80"
          name="last_name"
          size="20"
          type="text"
          v-bind:placeholder="$t('formgeneric.last_name')"
        ></b-form-input>
        <p class="error">{{ validationErrors.last_name }}</p>
        <p v-if="!validationErrors.last_name" class="blank-p"></p>

        <b-form-input
          required
          v-model="email"
          @input="validateEmail"
          id="email"
          maxlength="80"
          name="email"
          size="20"
          type="email"
          v-bind:placeholder="$t('formgeneric.email')"
        ></b-form-input>
        <p class="error">{{ validationErrors.email }}</p>
        <p v-if="!validationErrors.email" class="blank-p"></p>

        <b-form-input
          required
          v-model="phone"
          @input="validatePhone"
          v-bind:placeholder="$t('formgeneric.phone')"
          id="phone"
          maxlength="40"
          name="phone"
          size="70"
          type="text"
        ></b-form-input>
        <p class="error">{{ validationErrors.phone }}</p>
        <p v-if="!validationErrors.phone" class="blank-p"></p>

        <b-row>
          <b-col cols="12" lg="6">
            <b-form-select
              v-model="country"
              @input="validateCountry"
              id="country"
              name="country"
              title="País"
              :options="countryOptions"
              v-bind:placeholder="$t('formgeneric.country')"
            ></b-form-select>
            <p class="error">{{ this.validationErrors.country }}</p>
            <p v-if="!validationErrors.country" class="blank-p"></p>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-input
              required
              v-model="city"
              @input="validateCity"
              id="city"
              maxlength="40"
              name="city"
              size="20"
              type="text"
              v-bind:placeholder="$t('formgeneric.city')"
            ></b-form-input>
            <p class="error">{{ validationErrors.city }}</p>
            <p v-if="!validationErrors.city" class="blank-p"></p>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-center mt-2">
          <b-form-group
            hidden
          >
            <b-button type="submit" id="submit" variant="black">
              {{
                $t(
                  leadSource === "Website - Quiero Info B2C"
                    ? !isLoading
                      ? "formgeneric.send"
                      : "formgeneric.sending"
                    : !isLoading
                    ? "formgeneric.download"
                    : "formgeneric.sending"
                )
              }}
            </b-button>
            <p v-if="!state" class="blank-p"></p>
          </b-form-group>
          <b-form-group>
            <b-button variant="black" @click="handleSubmit">
              {{
                  $t(
                    leadSource === "Website - Quiero Info B2C"
                      ? !isLoading
                        ? "formgeneric.send"
                        : "formgeneric.sending"
                      : !isLoading
                      ? "formgeneric.download"
                      : "formgeneric.sending"
                  )
                }}
            </b-button>
            <p v-if="!state" class="blank-p"></p>
          </b-form-group>
        </div>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import { i18n } from "../plugins/i18n";
import { LoadSource } from "../plugins/loudSource";
export default {
  name: "FormGenericB2C",
  props: {
    leadSource: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      leadSourceOptions: [
        { value: LoadSource.donwloadjava, text: LoadSource.donwloadjava },
        { value: LoadSource.downloadmern, text: LoadSource.downloadmern },
        { value: LoadSource.iwantinfo, text: LoadSource.iwantinfo },
      ],
      countryOptions: [
        {
          value: "",
          text: i18n.locale === "es" ? "Pais *" : "Country *",
          disabled: true,
        },
        { value: "Argentina", text: "Argentina" },
        { value: "Brasil", text: "Brasil" },
        { value: "Bolivia", text: "Bolivia" },
        { value: "Centroamérica", text: "Centroamérica" },
        { value: "Chile", text: "Chile" },
        { value: "Colombia", text: "Colombia" },
        { value: "Ecuador", text: "Ecuador" },
        { value: "España", text: "España" },
        { value: "Estados Unidos", text: "Estados Unidos" },
        { value: "México", text: "México" },
        { value: "Paraguay", text: "Paraguay" },
        { value: "Perú", text: "Perú" },
        { value: "Uruguay", text: "Uruguay" },
        { value: "Otros", text: "Otros" },
      ],
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      country: "",
      city: "",
      lead_source: this.leadSource,
      validationErrors: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        country: null,
        city: null,
      },
      isLoading: null,
      valid: "",
      error: "",
      state: null,
      retURL: window.location.href.includes("#sended") ? window.location.href : window.location.origin + window.location.pathname + "#sended"
    };
  },
  methods: {
    validateFirstName() {
      this.validationErrors.first_name = this.first_name
        ? null
        : this.lang == "es"
        ? "Nombre es requerido"
        : "Name is required";
    },

    validateLastName() {
      this.validationErrors.last_name = this.last_name
        ? null
        : this.lang == "es"
        ? "Apellido es requerido"
        : "Last Name is required";
    },

    validateEmail() {
      this.validationErrors.email = this.validateRegexEmail()
        ? null
        : this.lang == "es"
        ? "Direccion de email invalida"
        : "Invalid email address";
    },

    validatePhone() {
      this.validationErrors.phone = this.validateRegexPhone()
        ? null
        : this.lang == "es"
        ? "Teléfono no es válido"
        : "Invalid phone number";
    },

    validateCountry() {
      this.validationErrors.country = this.country
        ? null
        : this.lang == "es"
        ? "Pais es requerido"
        : "Country is required";
    },

    validateCity() {
      this.validationErrors.city = this.city
        ? null
        : this.lang == "es"
        ? "Ciudad es requerida"
        : "City is required";
    },

    validateRegexEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },

    validateRegexPhone() {
      const phoneRegex = /^\d+$/;
      return phoneRegex.test(this.phone);
    },

    validateInputs() {
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validatePhone();
      this.validateCountry();
      this.validateCity();

      return Object.values(this.validationErrors).some(
        (err) => Boolean(err) !== false
      );
    },

    isFirstNameValid() {
      return this.first_name.trim() !== "" || !this.validationErrors.first_name;
    },

    isLastNameValid() {
      return this.last_name.trim() !== "" || !this.validationErrors.last_name;
    },

    handleSubmit() {
    
      if (this.validateInputs()) {
        return;
      } else {
        this.isLoading = true;

        const buttonSubmit = document.getElementById("submit")

        buttonSubmit.click()
      }
    },
  },
  computed: {
    lang() {
      return i18n.locale;
    },
    formContainerClass() {
      const classes = {
        "form-container": true,
        "form-container-quiero-info":
          this.leadSource === "Website - Quiero Info B2C",
        "form-container-java":
          this.leadSource === "Website - Syllabus B2C Java",
        "form-container-mern":
          this.leadSource === "Website - Syllabus B2C MERN",
      };
      console.log(classes);
      return classes;
    },
  },
};
</script>

<style scoped>
.half-width {
  width: 50%;
}

.error {
  color: #dc3545;
  font-size: 14px;
  margin: 0px 0px 0px 3px;
  text-align: start;
}

.btn-black {
  font-size: 1.4rem;
  padding: 5px 50px !important;
  background-color: #000 !important;
  color: #fff !important;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 100%;
  color: #baff43;
}
.btn-black:hover {
  color: #baff43 !important;
}

.form-container {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.form-container-quiero-info {
  background-image: url("~@/assets/formulario.webp");
}

.form-container-java {
  background-image: url("~@/assets/bt1.jpg");
}

.form-container-mern {
  background-image: url("~@/assets/bt3.jpg");
}

.info-text {
  flex: 1;
  text-align: center;
  max-width: 600px;
  padding: 20px;
  font-size: 1.4rem;
}

.info-text h4 {
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
}

form {
  flex: 1;
  max-width: 600px;
  padding: 20px;
}

input,
select {
  width: 100%;
  margin-top: 0.5rem;
}

.button-container {
  text-align: right;
}

.blank-p {
  height: 21px;
  margin: 0;
  padding: 0;
}

@media (max-width: 900px) {
  .form-container {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .half-width {
    width: 100%;
  }
}
</style>
