<template>
  <div id="testimonials" class="pb-5">
    <div class="px-md-5 py-5">
      <b-carousel
        id="testimonial-carousel"
        :interval="4000"
        controls
        img-width="100"
        img-height="100"
      >
        <b-carousel-slide>
          <template slot="img">
            <Testimonial
              name="Agustina Chiodini"
              :stars="5"
              :text="$tc('testimonials.t1')"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <Testimonial
              name="Sebastián Valbuena"
              :stars="5"
              :text="$tc('testimonials.t2')"
            />
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <Testimonial
              name="Federico Val"
              :stars="5"
              :text="$tc('testimonials.t3')"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="mt-3">
        <a href="https://www.google.com/search?q=mindhub+la&sxsrf=ALeKk02tGXOA7XrkjiWHbCLjX-Ay4aFA1g%3A1620050281414&source=hp&ei=aQGQYM_xFo3F5OUPm9yRkAY&iflsig=AINFCbYAAAAAYJAPecNKvpBJ_i4ohwGqx5uMjeugye3-&oq=min&gs_lcp=Cgdnd3Mtd2l6EAEYADIECCMQJzIECCMQJzILCAAQsQMQxwEQowIyBQguELEDMggIABCxAxCDATIFCAAQsQMyCwgAELEDEMcBEKMCMgUIABCxAzIFCC4QsQMyAgguOggILhCxAxCDAVDOCFjNCmCQG2gAcAB4AIABbYgBnAKSAQMyLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz#lrd=0x95bccb96756ef42b:0xe81b5b162482c0cf,1,,,">
          <img id="google-reviews" src="@/assets/google-reviews.webp" alt="google reviews">
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Testimonial from "./testimonial";

export default {
  name: "Testimonials",
  components: {
    Testimonial,
  },
  data() {
    return {
      testimonial: null,
    };
  },
  methods: {
    getLastTestimonial() {
       
       
      const strapiUrl = process.env.VUE_APP_STRAPI_URL;
      const apiKey = process.env.VUE_APP_API_KEY;
      fetch(`${strapiUrl}/api/bootcamps`, {
          headers: {
          'Authorization': `Bearer ${apiKey}`,
          }
      })
        .then((res) => res.json())
        .then((json) => {
                    if(!json[0].publishedAt){
                        return null
                    }
                    this.testimonial = json
                });
    },
  },
  beforeMount() {
    this.getLastTestimonial();
  },
};
</script>
<style>
#testimonial-carousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f0f' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
#testimonial-carousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f0f' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

#google-reviews{
  width: 100px;
}

.btn-pink{
  background-color: #ff00ff !important;
  color: white !important;
  border-radius: 2em !important;
}

.btn-pink:hover{
  background-color: #0000ff !important;
  color: #baff00 !important;
}
</style>