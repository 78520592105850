<template>
  <div class="mx-auto testimonial-slide">
    <h4 class="font-weight-bolder">{{name}}</h4>
    <p>
      {{text}}
    </p>
    <div class="w-100">
        <span v-for="star in stars" :key="star" class="px-1">
            <b-icon  class="text-blue" icon="star-fill" font-scale="1.5"></b-icon>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Testimonial",
  props: ["name", "text", "stars"],
};
</script>

<style scoped>
    p{
        font-size: .7rem;
    }

    h4{
      font-size: 1.3rem
    }

    .testimonial-slide{
        width: 75%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }



    @media screen and (min-width: 992px) {
      .testimonial-slide{
        width: 50%;
      }

      h4{
        font-size: 1.5rem;
      }

      p{
        font-size: .85rem;
      }
    }
</style>