<template>
  <div id="app">
    <!-- Navbar -->
    <Navbar/>
    <!-- Main view -->
    <router-view/>
    <!-- Footer -->
    <Footer/>
  </div>
</template>
<script>
import Navbar from './components/navbar.vue'
import Footer from './components/footer.vue'

export default {
  name: 'App',
  components:{
    Navbar, 
    Footer
  }
}
</script>

<style>
#app {
   font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}

.text-pink{
  color: #ff00ff !important;
}

.text-blue{
  color: #0000ff !important;
}

.text-green{
  color: #baff00 !important;
}

.text-white{
  color: #ffffff !important;
}

.text-black{
  color: #000000 !important;
}

.bgk-pink{
  background-color: #ff00ff !important;
}

.bgk-blue{
  background-color: #0000ff !important;
}

.bgk-green{
  background-color: #baff00 !important;
}

.bgk-black{
  background-color: #000000 !important;
}

.btn-white{
    padding: 5px 50px!important;
    background-color: #fff!important;
    color: #000!important;
    font-weight: bold!important;
}

.btn-white:hover {
    color: #0000ff!important;
}

.btn-black{
    padding: 5px 50px!important;
    background-color: #000!important;
    color: #fff!important;
    font-weight: bold!important;
}

.btn-black:hover {
    color: #baff43!important;
}

.btn-green{
    background-color: #baff00!important;
    color: #000!important;
    font-weight: bold!important;
    border-radius: 2em !important;
}

.btn-green:hover {
    background-color: #ff00ff!important;
    color: #fff !important;
}

.btn-blue{
    background-color: #0000ff!important;
    color: #fff!important;
    font-weight: bold!important;
    border-radius: 2em !important;
}

.btn-blue:hover {
    background-color: #baff00!important;
    color: #000 !important;
}

@media screen and (min-width: 768px){
  .px-6{
    padding-right: 3rem!important;
    padding-left: 3rem!important;
  }
}

@media screen and (min-width: 1024px){
  .px-6{
    padding-right: 4rem!important;
    padding-left: 4rem!important;
  }
}

@media screen and (min-width: 1440px){
  .px-6{
    padding-right: 6rem!important;
    padding-left: 6rem!important;
  }
}


h1.main-title{
    font-weight: bolder;
    font-size: 1.5rem;
}

@media screen and (min-width: 425px){
    h1.main-title{
        font-size: 2rem;
    }
}

@media screen and (min-width: 768px){
    h1.main-title{
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 1024px){
    h1.main-title{
        font-size: 3rem;
    }
}

@media screen and (min-width: 1440px){
    h1.main-title{
        font-size: 3.5rem;
    }
}

.carousel{
  z-index: 998;
}


</style>
