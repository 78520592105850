<template>
    <div class="bg-light flex-grow-1 d-flex flex-column h-100" id="bootDescriptions">
        <h3 :class="{bgImg: true, javaImg: type == 'fs_java', mernImg: type == 'fs_mern'}">{{title1}} <br>{{ title2 }}</h3><br>
        
        <div class="px-5 d-flex flex-column flex-grow-1 justify-content-between">    
            <p class="px-6 text-left" v-html="description"></p>     
            <!-- Imagen en lugar de la selección de país hasta precio cuando type no es fs_java -->
            <template v-if="type != 'fs_java'">
                <!-- <img :src="
        require(this.lang == 'es'
          ? '../assets/parche__corregido_ES.png'
          : '../assets/parche__corregido_EN.png')
      " alt="Imagen"> -->
                <!-- <b-row>
                    <b-col>
                        <b-button :href="type == 'fs_java' ? 
                        'https://cloud.edu.mindhubweb.com/inscripcion?cohortId=701Qr000009V0PyIAK&utm_source=OrganicoWeb&utm_medium=webl&utm_campaign=&utm_content=' : 
                        'https://cloud.edu.mindhubweb.com/openhouse'" 
                        class="px-5 py-2 rounded-pill blueButton" variant="dark">{{$tc('bootcamp.goOpenHouse')}}</b-button>
                    </b-col>
                </b-row> -->
                <!-- <b-row>
                    <b-col>
                        <b-button @click="showModal()" :id="'download-' + type + '-btn'" class="px-4 py-2 rounded-pill" variant="dark">{{$tc('bootcamp.download')}}</b-button>
                    </b-col>
                </b-row> -->
            </template>
                         
            <b-container class="pr-md-5" v-else>
              
            <!-- Seleccionar país -->
            <!-- <b-row class="px-md-5 mb-3">
              <b-col>
                <b-form-select
                  v-model="selected"
                  :options="options"
                ></b-form-select>
              </b-col>
            </b-row> -->
            <!-- fechas  -->
            <!-- <b-row class="px-md-5 text-left mb-2">
              <b-col>
                  <p>
                    <b-icon-calendar3 class="text-blue"></b-icon-calendar3>
                    {{ $t("bootcamp.date") }}:
                    <template v-if="selected != null">
                      {{ bootcamp.date }}
                    </template>
                  </p>
              </b-col>
            </b-row> -->
            <!-- duracion -->
            <!-- <b-row class="px-md-5 text-left mb-2">
              <b-col>
                  <p>
                    <b-icon-stopwatch class="text-blue"></b-icon-stopwatch>
                    {{ $t("bootcamp.duration") }}:
                    <template v-if="selected != null">
                        {{ bootcamp.totalHours }}hs
                    </template>
                  </p>
              </b-col>
            </b-row> -->
            <!-- modalidad -->
            <!-- <b-row class="px-md-5 text-left mb-2">
              <b-col>
                  <p>
                    <b-icon-globe class="text-blue"></b-icon-globe>
                    {{ $t("bootcamp.modality") }}:
                    <template v-if="selected != null">
                      {{ $tc(`modalities.${bootcamp.modality}`) }}
                    </template>
                  </p>
              </b-col>
            </b-row> -->
            <!-- horarios -->
            <!-- <b-row class="px-md-5 text-left mb-2">
              <b-col>
                  <p>
                    <b-icon-clock class="text-blue"></b-icon-clock>
                    {{ $t("bootcamp.schedule") }}:
                    <template v-if="selected != null">
                      {{ fancyText(bootcamp.courseType) }}
                    </template>
                  </p>
              </b-col>
            </b-row> -->
            <!-- precio -->
            <!-- <b-row class="px-md-5 text-left mb-2">
              <b-col>
                  <p>
                    <b-icon-cart2 class="text-blue"></b-icon-cart2>
                     {{ $t("bootcamp.price") }}:
                    <template v-if="selected == 'Arg'">
                        USD {{ bootcamp.priceAR.toLocaleString("en") }} oficiales
                    </template>
                    <template v-else-if="selected == 'Chi'">
                        CLP {{ bootcamp.priceCHI.toLocaleString("en") }}
                    </template>
                    <template v-else-if="selected == 'Col'">
                        COP {{ bootcamp.priceCOL.toLocaleString("en") }}
                    </template>
                    <template v-else-if="selected == 'Uru'">
                        UYU {{ bootcamp.priceURU.toLocaleString("en") }}
                    </template>
                    <template v-else-if="selected == 'RM'">
                        USD {{ bootcamp.priceUSD.toLocaleString("en") }}
                    </template>
                  </p>
              </b-col>
            </b-row> -->
                <!-- <b-row>
                    <b-col>
                        <b-button :href="type == 'fs_java' ? 
                        'https://cloud.edu.mindhubweb.com/inscripcion?cohortId=701Qr000009V0PyIAK&utm_source=OrganicoWeb&utm_medium=webl&utm_campaign=&utm_content=' : 
                        'https://cloud.edu.mindhubweb.com/inscripcion?cohortId=7018c000001hhlnAAA&utm_source=OrganicoWeb&utm_medium=webl&utm_campaign=&utm_content='" 
                        class="px-3 py-2 rounded-pill blueButton" variant="dark">{{$tc('bootcamp.signUpNewJava')}}</b-button>
                    </b-col>
                </b-row> -->
                <!-- <b-row>
                    <b-col>
                        <b-button @click="showModal()" :id="'download-' + type + '-btn'" class="px-4 py-2 rounded-pill" variant="dark">{{$tc('bootcamp.download')}}</b-button>
                    </b-col>
                </b-row> -->
          </b-container>
        </div>

        <b-modal size="xl" :id="'download-'+ type +'-form'" title="Descargar Programa" hide-footer>
            <!-- <iframe :src="formSrc" frameborder="0" class="w-100" style="height: 80vh"></iframe> -->
            
            <FormGenericoB2C :leadSource="formSrc" />
        </b-modal>
    </div>
</template>

<script>
import { i18n } from '../plugins/i18n';
import { LoadSource } from '../plugins/loudSource';
import FormGenericoB2C from './formGenericoB2C.vue';

export default {
    name: 'Bootcamp',
    props: ['type'],
    data() {
        return {
            selected: null,
            bootcamps: null,
            formSrc: this.type == 'fs_java' ? LoadSource.donwloadjava : LoadSource.downloadmern
        };
    },
    computed: {
        title1() {
            if (this.type == 'fs_java') {
                return i18n.tc('bootcamp.java', 1);
            }
            else {
                return i18n.tc('bootcamp.mern', 1);
            }
        },
        title2() {
            if (this.type == 'fs_java') {
                return i18n.tc('bootcamp.java', 2);
            }
            else {
                return i18n.tc('bootcamp.mern', 2);
            }
        },
        desc() {
            if (this.type == 'fs_java') {
                return i18n.tc('bootcamp.javaP');
            }
            else {
                return i18n.tc('bootcamp.mernP');
            }
        },
        description() {
            if (this.type == 'fs_java') {
                return i18n.tc('bootcamp.javaDescription');
            }
            else {
                return i18n.tc('bootcamp.mernDescription');
            }
        },
        options() {
            return [
                { value: null, text: this.$t("bootcamp.opt1") },
                { value: "Arg", text: this.$t("bootcamp.opt2") },
                { value: "Chi", text: this.$t("bootcamp.opt3") },
                { value: "Col", text: this.$t("bootcamp.opt4") },
                { value: "Uru", text: this.$t("bootcamp.opt5") },
                { value: "RM", text: this.$t("bootcamp.opt6") },
            ];
        },
        syllabus() {
            if (this.type == 'fs_java') {
                return 'https://info.mindhubweb.com/hubfs/Website Syllabus/MindHub Full Stack Java- 2021.pdf';
            }
            else {
                return 'https://info.mindhubweb.com/hubfs/Website Syllabus/MindHub MERN Full Stack & Mobile Apps- 2021.pdf';
            }
        },
        bootcamp() {
            let no_data = {
                date: this.lang == "es" ? "no disponible" : "no data",
                totalHours: this.lang == "es" ? "no disponible" : "no data",
                modality: this.lang == "es" ? "no disponible" : "no data",
                courseType: this.lang == "es" ? "no disponible" : "no data",
                priceAR: this.lang == "es" ? "no disponible" : "no data",
                priceCHI: this.lang == "es" ? "no disponible" : "no data",
                priceCOL: this.lang == "es" ? "no disponible" : "no data",
                priceURU: this.lang == "es" ? "no disponible" : "no data",
                priceUSD: this.lang == "es" ? "no disponible" : "no data",
            };
            if (this.bootcamps != null && this.bootcamps.length != 0) {
                let bootcamps = this.bootcamps
                    .filter((boot) => boot.type?.includes(this.type)
                    && Date.now() <= new Date(boot.initDate));

                
                if (bootcamps.length != 0) {
                    let initDate = new Date(bootcamps[0].initDate);
                    return {
                        date: initDate.getDate() +
                            "/" +
                            (initDate.getMonth() + 1) +
                            "/" +
                            initDate.getFullYear(),
                        totalHours: bootcamps[0].totalHours,
                        modality: bootcamps[0].modality,
                        courseType: bootcamps[0].courseType,
                        priceAR: bootcamps[0].priceAR,
                        priceCHI: bootcamps[0].priceCHI,
                        priceCOL: bootcamps[0].priceCOL,
                        priceURU: bootcamps[0].priceURU,
                        priceUSD: bootcamps[0].priceUSD,
                    };
                }
                else {
                    return no_data;
                }
            }
            else {
                return no_data;
            }
        },
        lang() {
            return i18n.locale;
        },
    },
    methods: {
        getBootcamps() {
            const strapiUrl = process.env.VUE_APP_STRAPI_URL;
            const apiKey = process.env.VUE_APP_API_KEY;
            fetch(`${strapiUrl}/api/bootcamps`, {
                headers: {
                'Authorization': `Bearer ${apiKey}`,
                }
            })
                .then((res) => res.json())
                .then((json) => {
                    if(!json[0].publishedAt){
                        return null
                    }
                    this.bootcamps = json
                });
                
        },
        fancyText(str) {
            return str[0].toUpperCase() + str.replace("_", " ").slice(1);
        },
        showModal() {
            this.$root.$emit('bv::show::modal', 'download-' + this.type + '-form', '#download-' + this.type + '-btn');
        },
    },
    beforeMount() {
        this.getBootcamps();
    },
    components: { FormGenericoB2C }
}
</script>

<style scoped>
    .blackStrip{
        background-color: black;
        min-height: 40px;
    }   
    .bgImg{
        background-size: cover;
        background-position: center;
        padding: 14.8%;
        font-weight: 900;
    }
    .javaImg{
        background-image: url('../assets/foto_java.png');
    }

    .mernImg{
        background-image: url('../assets/foto_mern.png');
    }
</style>