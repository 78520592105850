<template>
  <div>
    <b-container fluid id="nuestros">
      <b-row>
        <b-col cols="12" lg="6">
          <h1>
            {{ $t("nuestrosboot.t1") }}
          </h1>
        </b-col>
      </b-row>
    </b-container>
    <b-container id="nb">
      <b-row class="py-5">
        <b-col>
          <p>
            {{ $t("nuestrosboot.p1") }}
          </p>
        </b-col>
      </b-row>
    </b-container>
    <!-- <b-container fluid>
      <b-row align-h="center position-relative py-5">
        <b-col
          id="laboral-number"
          cols="6"
          sm="3"
          class="py-5 d-flex align-items-center justify-content-center text-pink"
        >
          {{ $tc("nuestrosboot.p2") }}
        </b-col>
        <b-col cols="6" sm="3" class="py-5 d-flex align-items-center">
          <p
            id="laboral-p"
            class="m-0 p-0 text-blue text-left font-weight-bold"
          >
            {{ $tc("nuestrosboot.p3", 0) }} <br />
            {{ $tc("nuestrosboot.p3", 1) }}<br />
            {{ $tc("nuestrosboot.p3", 2) }}
          </p>
        </b-col>
        <div id="green-left-line"></div>
        <div id="blue-left-line"></div>
        <div id="green-right-line"></div>
        <div id="pink-right-line"></div>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-start">
          <img
            style="width: 50px"
            class="ml-5"
            src="../assets/ilustraciones/blue_stripes.png"
            alt=""
          />
        </b-col>
      </b-row>
    </b-container> -->
    <Bootcamps />
    <Financing />
    <MetodosPago />
    <Openhouse />
    <!-- <h2 class="bgk-green m-0 py-5">
        {{ $tc("bootcamp.how") }}
    </h2> -->
    <!-- <b-row class="steps my-5 px-5 mx-5" >
        <b-col >
          <b-row class="mb-3">
            <b-col class="col-2">
              <img width="90%" src="@/assets/uno.jpg" class='step'  alt="banner marcas"/>  
            </b-col>
            <b-col class="col-10">
              <h3> {{ $tc("bootcamp.apply") }}</h3>
              <p>{{ $tc("bootcamp.applyDescription") }}</p>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col class="col-2">
              <img width="90%" src="@/assets/dos.jpg" class='step'  alt="banner marcas"/>  
            </b-col>
            <b-col class="col-10">
              <h3> {{ $tc("bootcamp.form") }}</h3>
              <p>{{ $tc("bootcamp.formDescription") }}</p>
            </b-col>
          </b-row>
           <b-row class="mb-3">
            <b-col class="col-2">
              <img width="90%" src="@/assets/tres.jpg" class='step'  alt="banner marcas"/>  
            </b-col>
            <b-col class="col-10">
              <h3> {{ $tc("bootcamp.challenge") }}</h3>
              <p>{{ $tc("bootcamp.challengeDescription") }}</p>
            </b-col>
          </b-row>
        </b-col>

        <b-col >
          <b-row class="mb-3">
            <b-col class="col-2">
              <img width="90%" src="@/assets/cuatro.jpg" class='step'  alt="banner marcas"/>  
            </b-col>
            <b-col class="col-10">
              <h3> {{ $tc("bootcamp.blended") }}</h3>
              <p>{{ $tc("bootcamp.blendedDescription") }}</p>
            </b-col>
          </b-row>
           <b-row class="mb-3">
            <b-col class="col-2">
              <img width="90%" src="@/assets/cinco.jpg" class='step'  alt="banner marcas"/>  
            </b-col>
            <b-col class="col-10">
              <h3> {{ $tc("bootcamp.bootcamp") }}</h3>
              <p>{{ $tc("bootcamp.bootcampDescription") }}</p>
            </b-col>
          </b-row>
           <b-row class="mb-3">
            <b-col class="col-2">
              <img width="90%" src="@/assets/seis.jpg" class='step'  alt="banner marcas"/>  
            </b-col>
            <b-col class="col-10">
              <h3> {{ $tc("bootcamp.financing") }}</h3>
              <p>{{ $tc("bootcamp.financingDescription") }}</p>
            </b-col>
          </b-row>
        </b-col>
    </b-row> -->
    <!-- <div class="my-5">
      <b-button href="bootcamps#bootDescriptions" class="px-6 py-2 rounded-pill purpleButton">{{ $t("navblack.p2") }}</b-button>
    </div> -->

    <h1 class="text-pink pb-3">{{$tc('bootcamp.brandTitle')}}</h1>
    <h3 class="text-blue">{{$tc('bootcamp.title')}}</h3>
    <p class="px-2" style="max-width: 1000px; margin: .5rem auto">{{$tc('bootcamp.train')}}</p>
    <p class="font-weight-bold">{{$tc('bootcamp.join')}}</p>
    <img class="px-6 brands-style" src="@/assets/brands.png"  alt="banner marcas"/>
  </div>
</template>

<style>
#laboral-number {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
}

.brands-style {
  width: 100%;
}

#laboral-p {
  font-size: 1rem;
}

#green-left-line {
  position: absolute;
  background-color: #baff00;
  width: 75%;
  height: 15%;
  left: 0;
  top: 0;
}

#blue-left-line {
  position: absolute;
  background-color: #0000ff;
  width: 50%;
  height: 10%;
  left: 0;
  top: 15%;
}
.steps {
  max-width: 100%;
  text-align: left;
}
.step {
 background-color: #baff00;
}
.financing {
  max-width: 100%;
}
.steps h3{
  color: #0000ff;
}
.purpleButton{
  background-color: #FF00FF!important;
  border:none!important;
}
#green-right-line {
  position: absolute;
  background-color: #baff00;
  width: 75%;
  height: 15%;
  right: 0;
  bottom: 10%;
}

#pink-right-line {
  position: absolute;
  background-color: #ff00ff;
  width: 50%;
  height: 10%;
  right: 0;
  bottom: 0;
}

#nuestros {
  margin-top: 70px;
}

#nb p {
  text-align: center;
  color: #000;
  font-size: 1.5em;
}
#nuestros {
  background-image: url("../assets/banner/foto7.jpg");
  padding: 10% 5%;
  padding-top: 30%;
  color: white;
  background-size: cover;
  text-align: left;
  background-position: center;
}
#nuestros h1 {
  font-weight: bolder;
  font-size: 1.5rem;
}

@media screen and (min-width: 320px) {
  #nuestros {
    padding-top: 0%;
    margin-top: 0px;
  }
  #nuestros h1 {
    font-size: 2rem;
  }
  #nb p {
    font-size: 1em;
  }
}
@media screen and (min-width: 425px) {
  #nuestros {
    padding-top: 35%;
    margin-top: 0px;
  }
  #nuestros h1 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 768px) {
  #nuestros {
    padding-top: 20%;
    margin-top: 0px;
  }
  #nuestros h1 {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1000px) {
  #nuestros {
    padding-top: 15%;
    margin-top: 0px;
  }
  #nuestros h1 {
    font-size: 3rem;
  }
  #nb p {
    font-size: 1.5em;
  }

  #laboral-number {
    font-size: 6rem;
  }

  #laboral-p {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 1300px) {
  #nuestros {
    padding-top: 15%;
    margin-top: 0px;
  }
  #nuestros h1 {
    font-size: 3em;
  }
  #nb p {
    font-size: 1.5em;
  }
}
</style>

<script>
import Bootcamps from "@/components/bootcamps.vue";
import Financing from "@/components/financing.vue";
import MetodosPago from "@/components/MetodosPago.vue";
import Openhouse from "../components/openhouse.vue";
import { mapMutations } from "vuex";

export default {
  name: "NuestrosBootcamps",
  components: {
    Bootcamps,
    Financing,
    MetodosPago,
    Openhouse
},
  methods: {
    ...mapMutations(["styleNav"]),
  },
  beforeMount() {
    this.styleNav({ type: "dark", variant: "blue", logo: "logo_blanco.webp" });
  },
};
</script>
