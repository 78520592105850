<template>
  <div class="home">
    <!-- Carousel principal (ver: https://bootstrap-vue.org/docs/components/carousel) -->
    <Carousel />
    <!-- Banner 1 -->
    <div>
      <div id="banner1">
        <h4>{{ $tc("homebanner", 1) }}</h4>
        <h2 v-html="$tc('homebanner', 2)"></h2>
      </div>
    </div>
    <img
      width="100%"
      :src="
        require(this.lang == 'es'
          ? '../assets/calif_es.webp'
          : '../assets/calif_en.webp')
      "
    />

    <!-- Testimonials -->
    <Testimonials />
    <!-- Banner Prensa -->
    <div class="bgk-black pb-5" id="banner-prensa">
      <h2 class="text-green m-0 font-weight-bolder py-3">
        {{ $tc("press.banner.title") }}
      </h2>
      <div class="position-relative">
        <img
          src="@/assets/prensa/banner_prensa.webp"
          class="img-fluid"
          alt="banner prensa"
        />
        <div id="banner-prensa" class="position-absolute w-100">
          <b-button to="/prensa" class="d-bloc mx-auto" variant="green">{{
            $tc("press.banner.button")
          }}</b-button>
        </div>
      </div>
    </div>

    <!-- Empresas -->
    <Empresas />

    <!-- Quiero Información -->
    <div class="bgk-black">
      <FormGenericoB2C :leadSource="leadSource" />
    </div>
    <!-- Toast de form enviado -->
    <b-toast
      v-model="showToast"
      variant="success"
      :auto-hide-delay="10000"
      :title="$tc('formgeneric.toast.t1')"
    >
      {{ $t("formgeneric.toast.t2") }}
      <p>
        {{ $t("formgeneric.toast.t3") }}
        <span>
          <a
            href="https://cloud.edu.mindhubweb.com/openhouse"
            target="_blank"
            style="text-decoration: underline"
            >
            Open House
          </a>
        </span>.
      </p>
    </b-toast>
  </div>
</template>
<style scoped>
#banner1 {
  background-color: #000;
  padding: 5%;
  text-align: center;
  color: white;
  background-color: #000;
}
#banner1 h2 {
  font-weight: 900;
  font-size: 1rem;
}

#banner1 h4 {
  font-size: 0.5rem;
}

#banner-prensa {
  bottom: -1.5em;
}

@media screen and (min-width: 768px) {
  #banner1 h2 {
    font-size: 2rem;
  }
  #banner1 h4 {
    font-size: 1rem;
  }

  #banner-prensa {
    bottom: -1em;
  }
}

@media screen and (min-width: 1024px) {
  #banner1 h2 {
    font-size: 2.5rem;
  }
  #banner1 h4 {
    font-size: 1.5rem;
  }

  #banner-prensa {
    bottom: 1.5em;
  }
}

iframe {
  width: 100%;
  height: 1000px;
  border: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

@media screen and (min-width: 375px) {
  iframe {
    height: 900px;
  }
}

@media screen and (min-width: 425px) {
  iframe {
    height: 850px;
  }
}

@media screen and (min-width: 768px) {
  iframe {
    height: 700px;
  }
}
</style>
<script>
import Carousel from "../components/carousel.vue";
import Testimonials from "../components/testimonials/testimonials.vue";
import Empresas from "../components/empresas.vue";
// import Inscribete from '../components/inscribete.vue'
import { mapMutations } from "vuex";
import { i18n } from "@/plugins/i18n";
import FormGenericoB2C from "../components/formGenericoB2C.vue";

export default {
  name: "Home",
  components: {
    Carousel,
    Testimonials,
    Empresas,
    FormGenericoB2C,
  },
  data() {
    return {
      leadSource: 'Website - Quiero Info B2C',
      showToast: false,
    };
  },
  computed: {
    lang() {
      return i18n.locale;
    },
  },
  created() {
    this.verificarURL();
    this.clearSendedHash();
  },
  methods: {
    ...mapMutations(["styleNav"]),
    showAlert() {
      this.showToast = true;
    },
    verificarURL() {
      const url = window.location;
      console.log(url);
      const successSend = url.href.includes("#sended");

      if (successSend) {
        this.showAlert();
      }
    },
    clearSendedHash() {
      const location = window.location;
      if (location.hash) {
        setTimeout(() => {
          const newURL = location.href.split("#")[0];
          history.replaceState({}, document.title, newURL);
        }, 3000);
      }
    },
  },
  beforeMount() {
    this.styleNav({ type: "dark", variant: "blue", logo: "logo_blanco.webp" });
  },
};
</script>
