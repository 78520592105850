<template>
  <div>
    <b-container fluid id="somos">
      <b-row class="heading">
        <b-col cols="12" lg="6">
          <h1 class="text-white main-title">
            {{ $t("somosmh.t1") }}
          </h1>
        </b-col>
      </b-row>
    </b-container>
    <div id="info">
      <b-container>
        <b-row style="padding-bottom: 5%">
          <b-col>
            <div class="parrafos" style="padding-top: 0">
              <p>
                {{ $t("somosmh.p1") }}
              </p>
              <p>
                {{ $t("somosmh.p2") }}
              </p>
              <!-- <p>
                <b>
                  {{ $t("somosmh.p3") }}
                </b>
              </p> -->
            </div>
          </b-col>
        </b-row>

        <!-- <b-row style="padding-bottom: 5%">
          <b-col>
            <div id="boot2">
              <h2 class="text-pink">{{ $tc("somosmh.t2") }}</h2>
            </div>
          </b-col> somos_nuestros_resultados_negro
        </b-row> -->
      </b-container>

      <b-container fluid class="py-5">
        <b-row>
          <b-col>
            <h3 class="text-pink font-weight-bolder">
              {{ $tc("somosmh.vision.title") }}
            </h3>
          </b-col>
        </b-row>
        <b-row class="ceo px-5">
          <b-col>
            <div >
              <img
                :src="require(this.lang == 'es'
                        ? '../assets/somos/somos_nuestros_resultados_negro.png'
                        : '../assets/somos/somos_nuestros_resultados_INGLES_negro.png')
                    " alt="somos_nuestros_resultados_negro">
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid class="py-5">
        <b-row>
          <b-col>
            <h3 class="text-pink font-weight-bolder">
              {{ $tc("somosmh.founders") }}
            </h3>
          </b-col>
        </b-row>
        <b-row style="padding-top: 2%">
          <b-col>
            <div class="parrafos" style="padding-top: 0">
              <p>
                {{ $t("somosmh.p33") }}
              </p>
              <p>
                {{ $t("somosmh.p34") }}
              </p>
              <p>
                <!-- <b> -->
                  {{ $t("somosmh.p35") }}
                <!-- </b> -->
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row class="ceo px-5">
          <b-col cols="12" lg="4">
            <div class="p-5">
              <img
                class="img-fluid"
                src="../assets/somos/alejandra.png"
                alt="Alejandra Ripa"
              />
            </div>
            <a href="https://www.linkedin.com/in/alejandra-ripa-a3a7056/"
              ><h4>Alejandra Ripa</h4>
              <h5>Founder</h5>
              <svg
                width="35"
                height="35"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="linkedin"
                class="svg-inline--fa fa-linkedin fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                ></path>
              </svg>
            </a>
          </b-col>
          <b-col cols="12" lg="4">
            <div class="p-5">
              <img
                class="img-fluid"
                src="../assets/somos/vanessa.png"
                alt="Alejandra Ripa"
              />
            </div>
            <a href="https://www.linkedin.com/in/vanessa-taiah-50b1147/"
              ><h4>Vanessa Taiah</h4>
              <h5>Founder</h5>
              <svg
                width="35"
                height="35"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="linkedin"
                class="svg-inline--fa fa-linkedin fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                ></path>
              </svg>
            </a>
          </b-col>
          <b-col cols="12" lg="4">
            <div class="p-5">
              <img
                class="img-fluid"
                src="../assets/somos/patricia.png"
                alt="Alejandra Ripa"
              />
            </div>
            <a href="https://www.linkedin.com/in/patriciamartucci/"
              ><h4>Patricia Martucci</h4>
              <h5>Founder</h5>
              <svg
                width="35"
                height="35"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="linkedin"
                class="svg-inline--fa fa-linkedin fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                ></path>
              </svg>
            </a>
          </b-col>
        </b-row>
      </b-container>

      <!-- <Carousel2 /> -->

      <b-container class="px-2 px-lg-5">
        <b-row class="py-5">
          <b-col
            ><h3 class="text-pink font-weight-bolder">
              {{ $tc("somosmh.t3") }}
            </h3>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col
            ><p>
              {{ $tc("somosmh.p4", 0)
              }}<span class="text-blue"> {{ $tc("somosmh.p4", 1) }} </span>
              {{ $tc("somosmh.p4", 2) }}
            </p>
            <p>
              {{ $tc("somosmh.p5", 1)
              }}<span class="text-blue"> {{ $tc("somosmh.p5", 2) }} </span>
            </p>
            <p>
              {{ $t("somosmh.p6") }}
            </p>
            <p>
              {{ $tc("somosmh.p7")
              }}
              {{ $tc("somosmh.p8") }}
            </p>
          </b-col>
          <b-col>
            <p>
              <b class="text-pink font-weight-bolder">{{
                $tc("somosmh.p9", 1)
              }}</b>
              {{ $tc("somosmh.p9", 2) }}
            </p>
            <p>
              <b class="text-pink font-weight-bolder">{{
                $tc("somosmh.p10", 1)
              }}</b>
              {{ $tc("somosmh.p10", 2) }}
            </p>
            <p>
              <b class="text-pink font-weight-bolder">{{
                $tc("somosmh.p11", 1)
              }}</b>
              {{ $tc("somosmh.p11", 2) }}
            </p>
            <p>
              <b class="text-pink font-weight-bolder">{{
                $tc("somosmh.p12", 1)
              }}</b>
              {{ $tc("somosmh.p12", 2) }}
            </p>
          </b-col>
        </b-row> -->
        <!-- <b-row class="py-5">
          <b-col>
            <p class="text-center">
              <b>
                {{ $tc("somosmh.p13") }}
              </b>
            </p>
          </b-col>
        </b-row>
        <b-row class="py-2">
          <b-col>
            <h3 class="text-pink font-weight-bolder">
              {{ $tc("somosmh.t4", 0) }} {{ $tc("somosmh.t4", 1) }} <br />{{
                $tc("somosmh.t4", 2)
              }}
            </h3></b-col
          >
        </b-row> -->
      </b-container>
      <NuestrosMercados />
      <div style="margin-top: 5% ">
        <b-button href="https://cloud.edu.mindhubweb.com/empresas" class="px-5 py-2 rounded-pill" variant="black">{{
          $t("somosmh.button")
        }}</b-button>
      </div>
    </div>
  </div>
</template>
<style scoped>
#bt4 {
  background-image: url("../assets/Prensa.jpg");
  background-size: cover;
  background-position: center;
  padding: 10%;
  margin-bottom: 5%;
}

#bt4 h1 {
  color: #baff43 !important;
  text-align: center !important;
  margin-bottom: 30px;
}
#bt4 input {
  padding: 5px 10%;
  font-size: 1.3em;
}
#bt4 input:hover {
  background-color: #000;
  border: 1px solid #baff43;
  color: #fff;
}
a {
  color: #000;
}
a:hover {
  color: #000;
  text-decoration: none;
}

#somos {
  background-image: url("../assets/somos/somos_banner.jpg");
  padding: 10% 5%;
  padding-top: 30%;
  color: #baff43;
  background-size: cover;
  text-align: left;
  background-position: top;
}
#info .ceo h4 {
  font-weight: bolder;
}

@media screen and (max-width: 325px) {
  #info .ceo h4 {
    font-size: 1rem;
  }

  #info .ceo h5 {
    font-size: 1rem;
  }

  #info .ceo svg {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 425px) {
  #info h4 {
    font-size: 1.2rem;
  }

  #info h5 {
    font-size: 1.1rem;
  }
}
#info h2 {
  padding: 0 11%;
  text-align: center;
  color: #0000ff;
  font-weight: 900;
  font-size: 2em;
}
#info h1 {
  padding: 0 20%;
  text-align: left;
  color: #0000ff;
  font-weight: 900;
  font-size: 4em;
}

#info img {
  width: 100%;
  height: auto;
}
#info {
  background-color: #fff;
  padding: 5% 0;
  justify-content: space-evenly;
}
#info row {
  width: 100% !important;
  display: flex !important;
}
#info p {
  text-align: center;
  color: #000;
  font-size: 1.5em;
}
@media screen and (min-width: 320px) {
  #somos {
    padding-top: 0%;
   
  }

  #info h1 {
    padding: 0;
    font-size: 2rem;
  }
  .parrafos p {
    text-align: center !important;
    padding: 0 !important;
  }
  #info p {
    font-size: 1em !important;
  }
}
@media screen and (min-width: 425px) {
  #somos {
    padding-top: 35%;
   
  }

  #info h1 {
    padding: 0;
    font-size: 2rem;
  }

  .parrafos p {
    text-align: center !important;
    padding: 0 !important;
  }
  #info p {
    font-size: 1em !important;
  }
}
@media screen and (min-width: 768px) {
  #somos {
    padding-top: 20%;
   
  }
  #info h1 {
    font-size: 2rem;
  }
  .parrafos p {
    text-align: center !important;
    padding: 0 !important;
  }
  #info p {
    text-align: center;
    font-size: 1em !important;
  }
}

@media screen and (min-width: 1024px) {
  #somos {
    padding-top: 15%;
   
  }
  #info h1 {
    font-size: 3.5em;
  }

  #info p {
    padding: 0;
    font-size: 1em !important;
    text-align: left;
  }
}

@media screen and (min-width: 1440px) {
  #somos {
    padding-top: 15%;
   
  }
  #info h1 {
    font-size: 4em;
  }
  #info p {
    font-size: 1.3em !important;
  }
}
</style>
<script>
import { mapMutations } from "vuex";
import { i18n } from '../plugins/i18n';
import NuestrosMercados from "../components/somos/nuestrosMercados.vue"
// import Carousel2 from "../components/carousel2.vue";
export default {
  name: "SomosMindhub",
  components: {
    NuestrosMercados
    // Carousel2,
  },
  computed: {
    lang() {
            return i18n.locale;
        },
  },
  methods: {
    ...mapMutations(["styleNav"]),
  },
  beforeMount() {
    this.styleNav({ type: "dark", variant: "blue", logo: "logo_blanco.webp" });
  },
};
</script>
