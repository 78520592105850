<template>
  <div>
      <b-container>
          <b-row class="py-5">
              <b-col cols="2" class="py-5 d-none d-md-block">
                  <img style="width: 50px" src="../assets/ilustraciones/pink_stripes.png" alt="">
              </b-col>
              <b-col cols="12" md="8" class="py-5">
                  <h1 class="text-pink pb-3">{{$tc('financing.title')}}</h1>
                  <p class="text-blue">
                      {{$tc('financing.desc',1)}} <br>
                      {{$tc('financing.desc',2)}}
                  </p>
              </b-col>
              <b-col cols="2" class="d-none d-md-block">
                  <img class="img-fluid" src="../assets/ilustraciones/frame.png" alt="">
              </b-col>
          </b-row>
          <b-row>
              <b-col cols="12" lg="4">
                  <div class="py-4 px-5 mx-5">
                    <img class="img-fluid" src="../assets/ilustraciones/traditional.png" alt="">
                  </div>
                  <div class="bgk-blue p-3 text-white">
                      <h3 class="pb-3 font-weight-bold">{{$tc('financing.trad.title')}}</h3>
                      <p class="p-0 m-0">{{$tc('financing.trad.desc')}}</p>
                  </div>
                  <div class="py-5 px-3">
                      <p>{{$tc('financing.trad.p')}}</p>
                  </div>
              </b-col>
              <b-col cols="12" lg="4">
                  <div class="py-4 px-5 mx-5">
                    <img class="img-fluid" src="../assets/ilustraciones/work.png" alt="">
                  </div>
                  <div class="bgk-green p-3 text-black">
                      <h3 class="pb-3 text-black font-weight-bold">{{$tc('financing.work.title')}}</h3>
                      <p class="p-0 m-0">{{$tc('financing.work.desc')}}</p>
                  </div>
                  <div class="py-5 px-3">
                      <p>{{$tc('financing.work.p')}}</p>
                  </div>
              </b-col>
              <b-col cols="12" lg="4">
                  <div class="py-4 px-5 mx-5">
                    <img class="img-fluid" src="../assets/ilustraciones/blended.png" alt="">
                  </div>
                  <div class="bgk-pink p-3 text-white">
                      <h3 class="pb-3 font-weight-bold">{{$tc('financing.blend.title')}}</h3>
                      <p class="p-0 m-0">{{$tc('financing.blend.desc')}}</p>
                  </div>
                  <div class="py-5 px-3">
                      <p>{{$tc('financing.blend.p')}}</p>
                  </div>
              </b-col>
          </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
    name: 'Financing'
}
</script>

<style>

</style>